import { LabTest } from "./labTests";
import { useAPI } from "./useHooks";
import { useQuery } from "react-query";

export interface LabSamplingContainer {
  id: string;

  name: string;
  description: string;

  lab_tests: string[];
}

export type LabSamplingContainerFull = Omit<
  LabSamplingContainer,
  "lab_tests"
> & {
  lab_tests: LabTest[];
};

export type LabSamplingContainerUnsavedFull = Omit<
  LabSamplingContainerFull,
  "id"
> & {
  id: string | undefined;
};

export const useLabSamplingContainers = () => {
  const { getJson } = useAPI();

  return useQuery(["lab-sampling-containers"], () =>
    getJson<LabSamplingContainer[]>("/api/v1/labs/containers")
  );
};
