import { StyledFormSectionRows } from "../../components/Form";
import { CheckboxField, TextField } from "../../components/FormComponents";
import {
  useCreateAndGetLabels,
  useGetExistingLabels,
} from "../../hooks/labels";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";

export const Labels: React.FC = () => {
  const getExistingLabel = useGetExistingLabels();
  const createAndGetLabels = useCreateAndGetLabels();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const toggleDialog = () => setOpen((c) => !c);

  const onPrintLabelsInner = async (values: any) => {
    const resp = await createAndGetLabels(values);
    const pdf = await resp.blob();
    const fileName = resp.headers.get("content-disposition") ?? "label2.pdf";

    const data = window.URL.createObjectURL(pdf);
    const link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    link.click();
    return null;
  };

  const onPrintLabels = (values: any) =>
    toast.promise(onPrintLabelsInner(values), {
      pending: "Creating labels",
      success: "Labels Created",
      error: "Failed to create labels",
    });

  const onReprintLabelInner = async (values: any) => {
    if (values.sampleId.trim()) {
      const resp = await getExistingLabel(values);
      const pdf = await resp.blob();
      const fileName = resp.headers.get("content-disposition") ?? "label.pdf";
      const data = window.URL.createObjectURL(pdf);
      const link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
    }
    return null;
  };

  const onReprintLabel = async (values: any) =>
    toast.promise(onReprintLabelInner(values), {
      pending: "Reprinting label",
      success: "Label reprinted",
      error: "Failed to reprint label",
    });

  return (
    <>
      <Formik
        initialValues={{
          count: 1,
          bottle1: true,
          bottle2: true,
          bag: true,
        }}
        onSubmit={onPrintLabels}
      >
        <Form autoComplete="off">
          <StyledFormSectionRows>
            <TextField label="Count of Labels" name={`count`} id="count" />
            <CheckboxField label="Bottle 1" name={`bottle1`} id="bottle1" />
            <CheckboxField label="Bottle 2" name={`bottle2`} id="bottle2" />
            <CheckboxField label="Bag" name={`bag`} id="bag" />
            <Button color="primary" variant="contained" type="submit">
              Print Labels
            </Button>
          </StyledFormSectionRows>
        </Form>
      </Formik>

      <br />
      <Button color="primary" onClick={toggleDialog}>
        Reprint Label
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Enter the Sample ID to print it's label</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              sampleId: "",
              bottle1: true,
              bottle2: true,
              bag: true,
            }}
            onSubmit={onReprintLabel}
          >
            <Form autoComplete="false">
              <StyledFormSectionRows>
                <TextField label="Sample Id" name="sampleId" id="sampleId" />
                <br />
                <CheckboxField label="Bottle 1" name={`bottle1`} id="bottle1" />
                <CheckboxField label="Bottle 2" name={`bottle2`} id="bottle2" />
                <CheckboxField label="Bag" name={`bag`} id="bag" />
                <br />
                <Button color="primary" variant="contained" type="submit">
                  Print Label
                </Button>
              </StyledFormSectionRows>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};
