import { useField } from "formik";
import React from "react";
import {
  StyledFormHeader,
  StyledFormSectionRows,
  StyledFormSubheader,
} from "src/components/Form";
import {
  CheckboxField,
  DateTimeField,
  FloatField,
  NumberField,
  SelectField,
  SelectOption,
  TextFieldSlow,
} from "src/components/FormComponents";
import { SamplePaymentMethodType } from "src/models/sample";

export interface IntakeDetailsFormSection {
  received_temp: "" | number;
  received_at_datetime: string;
  received_on_ice: boolean;

  payment_amount: "" | number;
  payment_method: "" | SamplePaymentMethodType;
  payment_check_number: string;
}

const IntakeDetails: React.FC = () => {
  const [, meta] = useField<SamplePaymentMethodType | null>("payment_method");

  return (
    <>
      <StyledFormHeader>Laboratory Only</StyledFormHeader>
      <StyledFormSectionRows>
        <StyledFormSubheader>Sample Intake</StyledFormSubheader>
        {/* Received Temp */}
        <FloatField
          id="received_temp"
          name={`received_temp`}
          label="Temp &deg;C"
          decimalPlaces={1}
        />
        <DateTimeField
          id="received_at_datetime"
          name="received_at_datetime"
          label="Date Received"
        />
        {/* Received on Ice? */}
        <CheckboxField
          id="received_on_ice"
          name={`received_on_ice`}
          label="Received On Ice?"
        />
        <br />
        <StyledFormSubheader>Payment Info</StyledFormSubheader>
        {/* Payment Amount */}
        <NumberField
          id={`payment_amount`}
          name={`payment_amount`}
          label="Payment Amount"
        />
        {/* Payment Method */}
        <SelectField
          id="payment_method"
          name="payment_method"
          label="Payment Method"
          options={PaymentOptions}
        />
        {/* If its a check we want to collect the check number */}
        <TextFieldSlow
          id={`payment_check_number`}
          name={`payment_check_number`}
          label="Check Number"
          disabled={meta.value !== "check"}
        />
      </StyledFormSectionRows>
    </>
  );
};

const PaymentOptions: SelectOption<string>[] = [
  {
    id: "cash",
    name: "Cash",
  },
  {
    id: "check",
    name: "Check",
  },
  {
    id: "credit_card",
    name: "Credit Card",
  },
  {
    id: "bill_account",
    name: "Bill to Account",
  },
];

export default IntakeDetails;
