import { useLabTests } from "../../hooks/labTests";
import { useLabSamplingContainers } from "../../hooks/samplingContainers";
import {
  useLabSamplingKit,
  useLabSamplingKits,
  useSaveLabSamplingKit,
} from "../../hooks/samplingKits";
import SamplingKitForm from "./SamplingKitForm";
import React from "react";
import { useParams } from "react-router-dom";

const SamplingKitEdit: React.FC = () => {
  const { kitId } = useParams<{ kitId: string }>();

  const { data: thisKit } = useLabSamplingKit(kitId);
  const { data: kits } = useLabSamplingKits();
  const { data: containers } = useLabSamplingContainers();
  const { data: tests } = useLabTests();

  const saveLabSamplingKit = useSaveLabSamplingKit(kitId);

  if (!thisKit || !kits || !containers || !tests) {
    return null;
  }

  return (
    <>
      <h1>Editing Kit: {thisKit.name}</h1>
      <SamplingKitForm
        isEdit
        kit={thisKit}
        kits={kits}
        containers={containers}
        tests={tests}
        onFormSave={(data) =>
          saveLabSamplingKit.mutateAsync({ ...data, id: kitId })
        }
      />
    </>
  );
};

export default SamplingKitEdit;
