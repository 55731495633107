import { useField } from "formik";
import React from "react";
import {
  StyledFormHeader,
  StyledFormSectionRows,
  StyledFormSubheader,
} from "src/components/Form";
import {
  CheckboxField,
  DateField,
  FloatField,
  FloatOrTextField,
  SelectField,
  SelectOption,
  TextField,
  TextFieldSlow,
} from "src/components/FormComponents";
import { SampleResultJson } from "src/models/sample";

const ResultsForm: React.FC = () => {
  const [, { value: results }] = useField<SampleResultJson[]>("results");
  return (
    <>
      <StyledFormHeader>Test Results</StyledFormHeader>
      {results.map((r, index) => (
        <ResultsFormRow key={`result-${index}`} index={index} />
      ))}
    </>
  );
};

const PresentAbsentOptions: SelectOption<string>[] = [
  {
    id: "0",
    name: "Absent",
  },
  {
    id: "1",
    name: "Present",
  },
];

export interface ResultsFormSection {
  results: ResultsFormSectionRow[];
}

export interface ResultsFormSectionRow {
  lab_substance: string;
  method: string;
  value: string | number;
  detection_limit: string | number;
  regulatory_limit: string | number;
  analyst: string;
  date_analyzed: string;

  is_urgent: boolean;
  urgent_text: string;

  methods_available: SelectOption<string>[];

  can_edit_regulatory_limit: boolean;
  number_of_decimal_places: number;
  result_type: "Numeric" | "PresentAbsent";
}

const ResultsFormRow: React.FC<{ index: number }> = ({ index }) => {
  const [, { value: r }] = useField<ResultsFormSectionRow>(`results.${index}`);

  return (
    <React.Fragment>
      <StyledFormSubheader>{r.lab_substance}</StyledFormSubheader>
      <StyledFormSectionRows>
        <SelectField
          id={`results[${index}].method`}
          name={`results[${index}].method`}
          label="Method"
          options={r.methods_available}
        />
        <ResultFields name={`results[${index}]`} />
        <TextField
          id={`results[${index}].analyst`}
          name={`results[${index}].analyst`}
          label={"Analyst"}
        />
        <DateField
          id={`results[${index}].date_analyzed`}
          name={`results[${index}].date_analyzed`}
          label="Date Completed"
        />
        <UrgentFields name={`results.${index}`} />
      </StyledFormSectionRows>
    </React.Fragment>
  );
};

const ResultFields: React.FC<{ name: string }> = ({ name }) => {
  const [, { value: r }] = useField<ResultsFormSectionRow>(name);

  switch (r.result_type) {
    case "Numeric":
      return (
        <>
          <FloatOrTextField
            id={`${name}.value`}
            name={`${name}.value`}
            label={"Concentration"}
            decimalPlaces={r.number_of_decimal_places}
          />
          <FloatField
            id={`${name}.detection_limit`}
            name={`${name}.detection_limit`}
            label={"Limit of Detection"}
            decimalPlaces={r.number_of_decimal_places}
          />
          <FloatField
            id={`${name}.regulatory_limit`}
            name={`${name}.regulatory_limit`}
            label={"Reg Limit (MCL)"}
            disabled={!r.can_edit_regulatory_limit}
            decimalPlaces={r.number_of_decimal_places}
          />
        </>
      );
    case "PresentAbsent":
      return (
        <>
          <SelectField
            id={`${name}.value`}
            name={`${name}.value`}
            label="Present / Absent"
            options={PresentAbsentOptions}
          />
          <SelectField
            id={`${name}.regulatory_limit`}
            name={`${name}.regulatory_limit`}
            label="Reg Limit (MCL)"
            options={PresentAbsentOptions}
            disabled={!r.can_edit_regulatory_limit}
          />
        </>
      );
    default:
      return null;
  }
};

const UrgentFields: React.FC<{ name: string }> = ({ name }) => {
  const [, meta] = useField(`${name}.is_urgent`);

  return (
    <>
      <CheckboxField
        id={`${name}.is_urgent`}
        name={`${name}.is_urgent`}
        label="Is Urgent"
      />
      <TextFieldSlow
        id={`${name}.urgent_text`}
        name={`${name}.urgent_text`}
        label={"Urgent Message"}
        disabled={!meta.value}
      />
    </>
  );
};

export default ResultsForm;
