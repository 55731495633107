import {
  CollectionDetailsFormSection,
  CollectionDetailsFormSectionRow,
  groupTests,
} from "./CollectionDetailsSection";
import { IntakeDetailsFormSection } from "./IntakeDetails";
import { ReportToDetailsFormSection } from "./ReportToDetails";
import { ResultsFormSection, ResultsFormSectionRow } from "./ResultsForm";
import { SiteDetailsFormSection } from "./SiteDetails";
import { intersection } from "lodash";
import {
  SampleJson,
  CollectionDetails,
  SampleResultJson,
  SampleAvailableTests,
  SampleEditable,
} from "src/models/sample";

export type SampleFormType = SiteDetailsFormSection &
  ReportToDetailsFormSection &
  CollectionDetailsFormSection &
  IntakeDetailsFormSection &
  ResultsFormSection & {
    custom_fields: any;
  };

export const sampleToSampleForm = (
  initialSample: Partial<SampleJson>
): SampleFormType => {
  const {
    sample_id = "",
    site_owner,
    site_address_street,
    site_address_city,
    site_address_state,
    site_address_zip,
    site_address_county,
    site_address_township,

    report_to_name,
    report_to_email,
    report_cc_email,
    report_to_address_street,
    report_to_address_city,
    report_to_address_state,
    report_to_address_zip,
    report_to_phone_number,
    report_to_fax_number,

    report_to_health_department,
    report_to_department_of_ag,

    hd_water_supply_serial_number,
    hd_well_permit_number,
    hd_property_tax_id,

    collection_details,
    tests_available,
    tests_selected,
    custom_fields,

    received_temp,
    received_at_datetime,
    received_on_ice,
    payment_amount,
    payment_method,
    payment_check_number,

    results,
  } = initialSample;

  return {
    sample_id,

    site_owner: site_owner ?? "",
    site_address_street: site_address_street ?? "",
    site_address_city: site_address_city ?? "",
    site_address_state: site_address_state ?? "",
    site_address_zip: site_address_zip ?? "",
    site_address_county: site_address_county ?? "",
    site_address_township: site_address_township ?? "",

    report_to_name: report_to_name ?? "",
    report_to_email: report_to_email ?? "",
    report_cc_email: report_cc_email ?? "",
    report_to_address_street: report_to_address_street ?? "",
    report_to_address_city: report_to_address_city ?? "",
    report_to_address_state: report_to_address_state ?? "",
    report_to_address_zip: report_to_address_zip ?? "",
    report_to_phone_number: report_to_phone_number ?? "",
    report_to_fax_number: report_to_fax_number ?? "",

    report_to_health_department: report_to_health_department ?? false,
    report_to_department_of_ag: report_to_department_of_ag ?? false,

    hd_water_supply_serial_number: hd_water_supply_serial_number ?? "",
    hd_well_permit_number: hd_well_permit_number ?? "",
    hd_property_tax_id: hd_property_tax_id ?? "",

    collection_details: collectionDetailsToFormDetails(
      collection_details,
      tests_available ?? [],
      tests_selected ?? []
    ),
    custom_fields: {
      // Needed to keep control from treating new_well as array
      new_well: false,
      ...custom_fields,
    },

    received_temp: received_temp ?? "",
    received_at_datetime: received_at_datetime ?? "",
    received_on_ice: received_on_ice ?? false,
    payment_amount: payment_amount ?? "",
    payment_method: payment_method ?? "",
    payment_check_number: payment_check_number ?? "",

    results: sampleResultsToFormResults(results ?? []),
  };
};

const collectionDetailsToFormDetails = (
  collectionDetails: CollectionDetails[] | undefined,
  testsAvailable: SampleAvailableTests,
  tests_selected: string[]
): CollectionDetailsFormSectionRow[] => {
  const groups = groupTests(testsAvailable);

  if (collectionDetails) {
    return groups.map((tests) => {
      const test_names = intersection(tests, tests_selected);

      const firstSelected = collectionDetails.find((x) =>
        test_names.includes(x.test_name ?? "")
      );

      return {
        available_test_names: tests,
        selected_test_names: test_names,
        collected_at_datetime: firstSelected?.collected_at_datetime ?? "",
        collected_at_location: firstSelected?.collected_at_location ?? "",
        collected_by_name: firstSelected?.collected_by_name ?? "",
      };
    });
  }
  return groups.map((tests) => ({
    available_test_names: tests,
    selected_test_names: [],
    collected_at_datetime: "",
    collected_at_location: "",
    collected_by_name: "",
  }));
};

const sampleResultsToFormResults = (
  results: SampleResultJson[]
): ResultsFormSectionRow[] => {
  return results.map(
    ({
      lab_substance,
      method,
      value,
      detection_limit,
      regulatory_limit,
      analyst,
      date_analyzed,
      is_urgent,
      urgent_text,
      methods_available,
      can_edit_regulatory_limit,
      number_of_decimal_places,
      result_type,
    }) => ({
      lab_substance,
      method: method ?? "",
      value: value ?? "",
      detection_limit: detection_limit ?? "",
      regulatory_limit: regulatory_limit ?? "",
      analyst: analyst ?? "",
      date_analyzed: date_analyzed ?? "",
      is_urgent: is_urgent ?? false,
      urgent_text: urgent_text ?? "",
      methods_available: methods_available.map((m) => ({
        id: m.method_name,
        name: m.method_name,
      })),
      can_edit_regulatory_limit,
      number_of_decimal_places: number_of_decimal_places ?? 2,
      result_type,
    })
  );
};

export const sampleFormToEditableSample = (
  values: SampleFormType
): SampleEditable => {
  const {
    received_temp,
    collection_details: to_update_collection_details,
    payment_method,
    payment_amount,
    results: to_update_results,
    ...restOfValues
  } = values;

  const collection_details = to_update_collection_details.flatMap((v) =>
    v.selected_test_names.map(
      (test_name): CollectionDetails => ({
        test_name,
        collected_at_datetime: v.collected_at_datetime,
        collected_at_location: v.collected_at_location,
        collected_by_name: v.collected_by_name,
      })
    )
  );

  const tests_selected = to_update_collection_details.flatMap(
    (x) => x.selected_test_names
  );

  const results = to_update_results.map((r): SampleResultJson => {
    return {
      method: r.method,
      value: typeof r.value === "number" ? r.value.toString() : r.value,
      detection_limit:
        typeof r.detection_limit === "number"
          ? r.detection_limit.toString()
          : r.detection_limit,
      regulatory_limit:
        typeof r.regulatory_limit === "number"
          ? r.regulatory_limit.toString()
          : r.regulatory_limit,
      analyst: r.analyst,
      date_analyzed: r.date_analyzed !== "" ? r.date_analyzed : null,
      is_urgent: r.is_urgent,
      urgent_text: r.urgent_text,
      unit: "",

      can_edit_regulatory_limit: r.can_edit_regulatory_limit,
      number_of_decimal_places: r.number_of_decimal_places ?? 2,
      lab_substance: r.lab_substance,
      methods_available: [],
      result_type: r.result_type,
    };
  });

  return {
    ...restOfValues,
    received_temp: received_temp === "" ? null : received_temp,
    collection_details,
    tests_selected,
    payment_method: payment_method === "" ? null : payment_method,
    payment_amount: payment_amount === "" ? null : payment_amount,
    results,
  };
};
