import { useLabTests } from "../../hooks/labTests";
import { useLabSamplingContainers } from "../../hooks/samplingContainers";
import {
  useLabSamplingKits,
  UnsavedLabSamplingKit,
  useCreateLabSamplingKit,
} from "../../hooks/samplingKits";
import SamplingKitForm from "./SamplingKitForm";
import React from "react";

const initialValues: UnsavedLabSamplingKit = {
  name: "",
  description: "",
  lab_containers: [],
};

const SamplingKitCreate: React.FC = () => {
  const { data: kits } = useLabSamplingKits();
  const { data: containers } = useLabSamplingContainers();
  const { data: tests } = useLabTests();

  const createLabSamplingKit = useCreateLabSamplingKit();

  if (!kits || !containers || !tests) {
    return null;
  }

  return (
    <>
      <h1>New Kit</h1>
      <SamplingKitForm
        kit={initialValues}
        kits={kits}
        containers={containers}
        tests={tests}
        onFormSave={(data) => createLabSamplingKit.mutateAsync(data)}
      />
    </>
  );
};

export default SamplingKitCreate;
