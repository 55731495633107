import {
  StyledActionsDiv,
  StyledFormHeader,
  StyledFormSectionRows,
} from "../../components/Form";
import {
  DateField,
  SelectMultipleGridField,
  SelectOption,
} from "../../components/FormComponents";
import { useGetExport } from "../../hooks/exports";
import { ExportFields, DEFAULT_EXPORT } from "../../models/exports";
import { downloadFile } from "./utils";
import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

const initialData: ExportParams = {
  start_date: "",
  end_date: "",
  fields: DEFAULT_EXPORT,
};

interface ExportParams {
  start_date: string;
  end_date: string;
  fields: Array<string>;
}

const exportColumns: SelectOption<string>[] = Object.values(ExportFields).map(
  (f) => ({
    id: f,
    name: f,
  })
);

export const Exports: React.FC = () => {
  const getExport = useGetExport();

  const handleSubmit = async (values: ExportParams) => {
    const { start_date, end_date, fields } = values;
    let file = await getExport(start_date, end_date, fields);
    downloadFile(new Blob([file.fileData]), file.fileName);
  };

  return (
    <>
      <StyledFormHeader>Exports</StyledFormHeader>
      <Formik
        // this form is reused so we start with initial values to let us use it when creating samples
        initialValues={initialData}
        // Can do form level validates
        // validate={(values) => {
        //   // Testing error feedback
        //   let errors = { name: "something bad" };
        //   return errors;
        // }}
        // validationSchema={sampleFormSchema} // may need to move this into a manual check to add extra logic
        onSubmit={handleSubmit}
      >
        <Form autoComplete="off">
          <StyledFormSectionRows>
            <DateField id="start_date" name="start_date" label="Start Date" />
            <DateField id="end_date" name="end_date" label="End Date" />
          </StyledFormSectionRows>
          <SelectMultipleGridField
            id="fields"
            name="fields"
            label="Export Fields"
            options={exportColumns}
          />
          <StyledActionsDiv>
            <Button color="primary" variant="contained" type="submit">
              Generate Export
            </Button>
          </StyledActionsDiv>
        </Form>
      </Formik>
    </>
  );
};
