import { useAPI } from "./useHooks";
import { LabProfile, useUser } from "./user";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateLab = () => {
  const { putJsonGetJson } = useAPI();
  const user = useUser();
  const queryClient = useQueryClient();

  if (user === null) {
    throw new Error('Can\'t use "useUpdateLab" outside of User context');
  }

  const labId = user.lab_id;

  return useMutation(
    (lab: LabProfile) => {
      const { id, name, ...rest } = lab;
      return putJsonGetJson(`/api/v1/labs/${labId}`, rest);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
};
