import styled from "styled-components";

export const StyledPublicPage = styled.div`
  max-width: 600px;
  margin: 0 auto;

  padding: 16px 32px;

  .actions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 16px 32px;

    & > * {
      margin-right: 16px;
    }

    & p {
      margin: unset;
    }
  }
`;

export const SampleIdForm = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
`;
