import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export function useAPI() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getAPIKey = useCallback(async (): Promise<string> => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      console.error(e);
      await loginWithRedirect();
      return "" as string;
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  const getJson = useCallback(
    async <T>(url: string): Promise<T> => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return (await resp.json()) as T;
      }

      throw new Error(resp as any);
    },
    [getAPIKey]
  );

  const getText = useCallback(
    async (url: string): Promise<string> => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return resp.text();
      }

      throw new Error("TODO");
    },
    [getAPIKey]
  );

  const getRaw = useCallback(
    async (url: string): Promise<Response> => {
      const accessToken = await getAPIKey();
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });
    },
    [getAPIKey]
  );

  const postJsonGetJson = useCallback(
    async (url: string, body: any): Promise<any> => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return resp.json();
      }
      const errorResp = await resp.json();
      throw new Error(errorResp);
    },
    [getAPIKey]
  );

  const postJsonGetRaw = useCallback(
    async (url: string, body: any): Promise<Response> => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }

      const errorResp = await resp.json();
      throw new Error(errorResp);
    },
    [getAPIKey]
  );

  const putJsonGetJson = useCallback(
    async (url: string, body: any) => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return resp.json();
      }

      const errorResp = await resp.json();
      throw new Error(errorResp);
    },
    [getAPIKey]
  );

  const putRawRequestTextResponse = useCallback(
    async (url: string, body: any) => {
      const accessToken = await getAPIKey();
      const resp = await fetch(url, {
        method: "PUT",
        body,
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "omit",
      });

      if (resp.status >= 200 && resp.status < 300) {
        return resp.json();
      }
    },
    [getAPIKey]
  );

  return {
    getJson,
    getText,
    getRaw,
    postJsonGetJson,
    postJsonGetRaw,
    putJsonGetJson,
    putRawRequestTextResponse,
  };
}
