import { UserProvider } from "./hooks/user";
import "./index.css";
import { PrivateApp } from "./routes/PrivateApp";
import { PublicApp } from "./routes/PublicApp";
import { theme } from "./theme";
import { Auth0Provider } from "@auth0/auth0-react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Bugsnag.start({
  apiKey: "79824cd869ecaa41fd4c18ee1afa9b74",
  plugins: [new BugsnagPluginReact(React)],
});
const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? React.Fragment;

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            domain={(window as any).AUTH0_DOMAIN}
            clientId={(window as any).AUTH0_CLIENT_ID}
            authorizationParams={{
              audience: (window as any).AUTH0_AUDIENCE,
              redirect_uri: window.location.origin,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <Router>
                <Switch>
                  <Route path="/embed/" component={PublicApp} />
                  <Route>
                    <UserProvider>
                      <PrivateApp />
                    </UserProvider>
                  </Route>
                </Switch>
              </Router>
              <ToastContainer position="bottom-center" />
            </QueryClientProvider>
          </Auth0Provider>
        </ThemeProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
};

export default App;
