import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { SendReportForm } from "src/hooks/samples";
import { SampleJson } from "src/models/sample";

const EmailReportButton: React.FC<{
  sendReport: (sample: SendReportForm) => void;
}> = ({ sendReport }) => {
  const { submitForm } = useFormikContext<SampleJson>();
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={() => {
        submitForm().then((response) => {
          const sample = response as any as SampleJson;
          sendReport({
            report_to_emails: sample.report_to_email,
            report_cc_emails: sample.report_cc_email,
            sample_id: sample.sample_id,
            email_subject: sample.email_subject,
            cc_me: false,
          });
        });
      }}
    >
      Email Report
    </Button>
  );
};

export default EmailReportButton;
