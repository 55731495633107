import { format, formatDistanceToNow } from "date-fns";
import { useField } from "formik";
import { sortBy } from "lodash";
import React from "react";
import { StyledFormHeader } from "src/components/Form";
import { StyledTable } from "src/components/Tables";
import { ReportSentInfo } from "src/models/sample";

const ReportsSent: React.FC = () => {
  const [, meta] = useField<ReportSentInfo[]>("all_reports_sent");
  const all_reports_sent = meta.value;

  if (!all_reports_sent || all_reports_sent.length === 0) {
    return null;
  }

  const sorted_reports = sortBy(all_reports_sent, "date_sent");

  return (
    <>
      <StyledFormHeader>Reports Sent</StyledFormHeader>
      <StyledTable>
        <thead>
          <tr>
            <th>Date Sent</th>
            <th>Sent To</th>
            <th>Sent CC</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {sorted_reports.map((x) => (
            <tr key={x.id}>
              <td>
                {format(new Date(x.date_sent), "Pp")}(
                {formatDistanceToNow(new Date(x.date_sent))})
              </td>
              <td>{x.sent_to.join(", ")}</td>
              <td>{x.cc_to.join(", ")}</td>
              <td>{x.subject}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default ReportsSent;
