import { formatToMask, isValidSampleId, SAMPLE_ID_MASK } from "../utils/sample";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";

interface SampleSelectionProps {
  nextPath: string;
}

export const SampleSelection: React.FC<SampleSelectionProps> = (props) => {
  const history = useHistory();
  const [sampleId, setSampleId] = useState("");
  const [sampleIdError, setSampleIdError] = useState("");

  function handleCancel(): void {
    history.goBack();
  }

  function handleAccept(): void {
    const nextPath = props.nextPath.replace(":sampleId", sampleId);
    history.replace(nextPath);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event) return;
    let { value } = event.target;
    let sampleId = formatToMask(SAMPLE_ID_MASK)(value);
    setSampleId(sampleId);
    if (!isValidSampleId(sampleId)) {
      setSampleIdError("Invalid Sample ID");
    } else {
      setSampleIdError("");
    }
  };

  return (
    <>
      <Dialog open={true}>
        <DialogTitle id="form-dialog-title">
          Select a Sample to Continue
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Sample ID"
            type="text"
            fullWidth
            value={sampleId}
            onChange={handleChange}
            error={!!sampleIdError}
            helperText={sampleIdError || undefined}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
