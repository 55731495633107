import React from "react";
import styled from "styled-components";

interface HeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height);

  padding: 8px 16px;
  background: var(--theme-main-color);

  color: white;

  /* Otherwise the inputs scroll over the header  */
  z-index: 3;
`;
