import { TemplateRenderer } from "../../components/TemplateRenderer";
import { TemplateTypeEnum } from "../../models/template";
import { Button } from "@mui/material";
import React, { } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SampleIdField } from "../../components/FormComponents";
import { Form, Formik } from "formik";
import { SampleIdForm, StyledPublicPage } from "../../components/PublicPages";

type ModeType = 'start' | 'confirmation';

interface MatchParams {
  labId?: string;
}

interface FormValues {
  sampleId: string;
}

const initialValues = {
  sampleId: ""
}

const PublicPage: React.FC<{ mode: ModeType }> = ({ mode }) => {
  const match = useRouteMatch<MatchParams>();

  const history = useHistory();

  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const labId = url.get("labId") || match.params.labId;


  const onSubmit = ({ sampleId }: FormValues) => {
    const labId = parseInt(sampleId.split("-")[0]);
    history.push(`/embed/collect-sample/${labId}/${sampleId}`);
  }

  return (
    <StyledPublicPage>
      <TemplateRenderer
        templateType={getTemplateType(mode)}
        labId={labId}
      />
      <p>{getMessage(mode)}</p>
      <div className="actions">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <SampleIdForm>
              <SampleIdField
                id="sampleId"
                label="Sample ID"
                name="sampleId"
                showQRScanner
              />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Start
                </Button>
              </div>
            </SampleIdForm>
          </Form>
        </Formik>
      </div>
    </StyledPublicPage>
  );
}

const getTemplateType = (mode: ModeType): TemplateTypeEnum => {
  switch (mode) {
    case 'confirmation':
      return TemplateTypeEnum.CONFIRMATION
    case 'start':
      return TemplateTypeEnum.LANDING;
  }

}

const getMessage = (mode: ModeType): string => {
  switch (mode) {
    case 'confirmation':
      return "To collect another sample please enter the 9 digit Id on the bottle label"
    case 'start':
      return 'To get started please enter the 9 digit Id on the bottle label'
  }
}

export default PublicPage;