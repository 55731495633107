import {
  SaveButton,
  StyledActionsDiv,
  StyledFormGroupRow,
  StyledFormHeader,
  StyledFormSectionRows,
  StyledFormSubheader,
} from "../../components/Form";
import { CheckboxField, TextField } from "../../components/FormComponents";
import { useUpdateLab } from "../../hooks/useLab";
import { EditableLabProfile, LabEmailGroup, useUser } from "../../hooks/user";
import { Add, RemoveCircle } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  FormHelperText,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  useFormikContext,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  useField,
} from "formik";
import React from "react";
import * as Yup from "yup";

const SettingSchema = Yup.object().shape({
  address_line_1: Yup.string(),
  address_line_2: Yup.string(),
  phone_number: Yup.string(),
  fax_number: Yup.string(),

  public_email: Yup.string().email(),
  website: Yup.string().url(),

  certification_number: Yup.string(),
  signature_name: Yup.string(),
  signature_title: Yup.string(),

  email_groups: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      sent_to: Yup.string().email(),
      cc_to: Yup.array().of(Yup.string().email()),
    })
  ),
});

const defaultValues: EditableLabProfile = {
  address_line_1: "",
  address_line_2: "",
  phone_number: "",
  fax_number: "",

  public_email: "",
  website: "",

  certification_number: "",
  signature_name: "",
  signature_title: "",

  email_groups: [],
};

export const Settings: React.FC = () => {
  const user = useUser();
  const updateLab = useUpdateLab();
  const lab = user?.lab;

  if (!lab) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{ ...defaultValues, ...lab }}
        onSubmit={(values) => updateLab.mutateAsync(values)}
        validationSchema={SettingSchema}
      >
        <Form autoComplete="off">
          <StyledFormHeader>Lab Information</StyledFormHeader>
          <StyledFormSubheader>Contact Info</StyledFormSubheader>
          <StyledFormSectionRows>
            <TextField
              id="address_line_1"
              name="address_line_1"
              label="Address Line 1"
            />
            <TextField
              id="address_line_2"
              name="address_line_2"
              label="Address Line 2"
            />
            <TextField id="phone_number" name="phone_number" label="Phone" />
            <TextField id="fax_number" name="fax_number" label="Fax" />
            <br />
            <TextField
              id="public_email"
              name="public_email"
              label="Public Email"
            />
            <TextField id="website" name="website" label="Website Address" />
          </StyledFormSectionRows>

          <StyledFormSubheader>Certification Info</StyledFormSubheader>
          <StyledFormSectionRows>
            <TextField
              id="certification_number"
              name="certification_number"
              label="Certification Number"
            />
            <TextField
              id="signature_name"
              name="signature_name"
              label="Report Signature Name"
            />
            <TextField
              id="signature_title"
              name="signature_title"
              label="Report Signature Title"
            />
          </StyledFormSectionRows>

          <EmailSettings />

          <StyledFormHeader>Other</StyledFormHeader>
          <StyledFormSectionRows>
            <CheckboxField
              id="settings.send_collection_confirmation_email"
              name="settings.send_collection_confirmation_email"
              label="Send Collection Confirmation Email"
            />
          </StyledFormSectionRows>

          <StyledActionsDiv>
            <SaveButton />
          </StyledActionsDiv>
        </Form>
      </Formik>
    </>
  );
};

const EmailSettings = () => {
  const [, meta] = useField("email_groups");
  const { values } = useFormikContext<EditableLabProfile>();

  console.log(meta);

  return (
    <div>
      <StyledFormHeader>Email Groups</StyledFormHeader>
      <FieldArray
        name="email_groups"
        render={(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {values.email_groups.map((group: LabEmailGroup, groupIdx) => {
              return (
                <StyledFormGroupRow key={groupIdx}>
                  <div className="field-content">
                    <TextField
                      id={`email_groups[${groupIdx}].name`}
                      name={`email_groups[${groupIdx}].name`}
                      label="Name"
                    />
                    <TextField
                      id={`email_groups[${groupIdx}].sent_to`}
                      name={`email_groups[${groupIdx}].sent_to`}
                      label="To"
                    />
                    <Field
                      name={`email_groups[${groupIdx}].cc_to`}
                      component={EmailListField}
                    />
                  </div>
                  <div className="field-remove">
                    <IconButton onClick={() => arrayHelpers.remove(groupIdx)}>
                      <RemoveCircle />
                    </IconButton>
                  </div>
                </StyledFormGroupRow>
              );
            })}
            <Button
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  name: "",
                  sent_to: "",
                  cc_to: [],
                })
              }
              variant="outlined"
              startIcon={<Add />}
            >
              Add Group
            </Button>
          </>
        )}
      />
    </div>
  );
};

const EmailListField: React.FC<FieldProps<string[]>> = (props) => {
  const [field, meta, helpers] = useField<string[]>(props.field.name);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    helpers.setValue(e.target.value.split(";").map((x) => x.trim()));
  };

  const { value } = field;
  const { error } = meta;

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor="cc-emails">CC Emails</InputLabel>
      <Input
        id="cc-emails"
        name={field.name}
        onBlur={field.onBlur}
        value={value.join("; ")}
        onChange={onChange}
      />
      {error ? (
        <FormHelperText error>
          Must be a semicolon separated list of valid emails
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
