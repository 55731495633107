import PublicPage from "../views/public/PublicPage";
import { CollectSample } from "../views/samples/CollectSample";
import React from "react";
import { Switch, Route } from "react-router-dom";

export const PublicApp: React.FC = () => (
  <Switch>
    <Route exact path="/embed/collect-sample/:labId/confirmation">
      <PublicPage mode="confirmation" />
    </Route>
    <Route exact path="/embed/collect-sample/:labId/:sampleId">
      <CollectSample />
    </Route>
    <Route exact path="/embed">
      <PublicPage mode="start" />
    </Route>
  </Switch>
);
