import { createTheme } from "@mui/material";

const pinkishOrange = "#ff7849";
// const darkPinkishOrange = "#f6622f";
// const dodgerBlue = "#448aff";

export const theme = createTheme({
  palette: {
    primary: {
      main: pinkishOrange,
      contrastText: "#efefef",
    },
    secondary: {
      main: "#efefef",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "Open Sans, Arial",
    h1: {
      fontSize: 32,
      color: "#2f2f2f",
    },
    h2: {
      fontSize: 28,
      color: "#2f2f2f",
    },
    h3: {
      fontSize: 24,
      color: "#4f4f4f",
    },
    h4: {
      fontSize: 20,
      color: "#4f4f4f",
    },
    h5: {
      fontSize: 16,
      color: "#4f4f4f",
    },
    h6: {
      fontSize: 12,
      color: "#4f4f4f",
    },
  },
});
