import { useGetTemplateForLabOrSample } from "../hooks/templates";
import { TemplateTypeEnum } from "../models/template";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

export function TemplateRenderer({
  templateType,
  labId,
  sampleId,
}: {
  templateType: TemplateTypeEnum;
  labId?: number | string;
  sampleId?: string;
}) {
  const [template, setTemplate] = useState("");
  const location = useLocation();
  const getTemplateForSample = useGetTemplateForLabOrSample();

  useEffect(() => {
    (async () => {
      if (labId) {
        let template = await getTemplateForSample(labId, templateType);
        setTemplate(template);
      } else if (sampleId) {
        let template = await getTemplateForSample(sampleId, templateType);
        setTemplate(template);
      }
    })();
  }, [getTemplateForSample, location, labId, sampleId, templateType]);

  return (
    <StyledTemplateContainer dangerouslySetInnerHTML={{ __html: template }}>
      {/* Some Stuff */}
    </StyledTemplateContainer>
  );
}

const StyledTemplateContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
