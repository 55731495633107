import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IconButton } from "@mui/material";
import { noop } from "lodash";
import React from "react";
import styled from "styled-components";

export const ContainerDiv = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ContainerTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .directionArrows {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  div {
    font-size: 3rem;
  }
`;

export const OrderHeader: React.FC<{
  moveUp: (() => void) | null;
  moveDown: (() => void) | null;
}> = ({ moveUp, moveDown }) => (
  <div className="directionArrows">
    <IconButton
      color="primary"
      aria-label="TODO"
      disabled={!moveUp}
      onClick={moveUp ?? noop}
      size="small"
    >
      <ArrowUpwardIcon />
    </IconButton>
    <IconButton
      color="primary"
      aria-label="TODO"
      disabled={!moveDown}
      onClick={moveDown ?? noop}
      size="small"
    >
      <ArrowDownwardIcon />
    </IconButton>
  </div>
);

export const ContainerContentDiv = styled.div`
  margin-top: 0.5rem;
  margin-left: 34px;
`;

export const ContainerTestDiv = styled.div`
  display: flex;

  align-items: center;

  min-height: 68px;
`;

export const TestTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .directionArrows {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  div {
    font-size: 3rem;
  }
`;

export const OrderPrefix = styled.div``;
