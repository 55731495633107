import {
  StyledActionsDiv,
  StyledFormHeader,
  StyledFormSectionRows,
} from "../../../components/Form";
import { SampleIdField, TextField } from "../../../components/FormComponents";
import { SampleListQuery } from "../../../hooks/samples";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik, Field, Form, useField, FieldProps } from "formik";
import React from "react";

interface SampleFilterFormProps {
  params: SampleListQuery;
  onParamsChange: (newParams: SampleListQuery) => void;
}

export const SampleFilterForm: React.FC<SampleFilterFormProps> = (props) => {
  const { params, onParamsChange } = props;

  return (
    <section>
      <StyledFormHeader>Sample Lookup </StyledFormHeader>
      {/* Form Here */}
      <Formik
        initialValues={params}
        onSubmit={async (values) => onParamsChange(values)}
        enableReinitialize
      >
        <Form autoComplete="off">
          <StyledFormSectionRows>
            <SampleIdField label="Sample ID" name="sample_id" id="sample_id" />
            <TextField label="Name" name="site_owner" id="site_owner" />
            <TextField
              label="Address"
              name="site_address_street"
              id="site_address_street"
            />
            <TextField
              label="Phone"
              name="report_to_phone_number"
              id="report_to_phone_number"
            />
            <TextField
              label="WSSN"
              name="hd_water_supply_serial_number"
              id="hd_water_supply_serial_number"
            />
            <FormControl variant="outlined" className="MuiTextField-root">
              <InputLabel htmlFor="status-input" filled>
                Status
              </InputLabel>
              <Field
                component={MultiSelectCheckboxList}
                name="status"
                color="primary"
                label="Status"
              />
            </FormControl>
          </StyledFormSectionRows>
          <StyledActionsDiv>
            <Button color="primary" variant="contained" type="submit">
              Search
            </Button>
          </StyledActionsDiv>
        </Form>
      </Formik>
    </section>
  );
};

// IDEA - Move all logic into MultiSelectCheckboxList (take list of values)

const MultiSelectCheckboxList: React.FC<FieldProps> = (props) => {
  const name = props.field.name;

  const [input, ,] = useField<string[]>(name);

  const renderValue = (selectedRaw: any) => {
    const selected = selectedRaw as string[];

    if (selected.length === 4) {
      return <em>All</em>;
    }

    return selected.join(", ");
  };

  return (
    <Select
      multiple
      {...input}
      renderValue={renderValue}
      id="status-input"
      label="Status"
    >
      <CheckedMenuItem name="status" label="Printed" itemValue="PRINTED" />
      <CheckedMenuItem name="status" label="Collected" itemValue="COLLECTED" />
      <CheckedMenuItem name="status" label="Received" itemValue="RECEIVED" />
      <CheckedMenuItem name="status" label="Reported" itemValue="REPORTED" />
    </Select>
  );
};

interface CheckedMenuItemProps {
  label: string;
  name: string;
  itemValue: string;
}

const CheckedMenuItem: React.FC<CheckedMenuItemProps> = (props) => {
  const { label, name, itemValue } = props;
  const [, meta, helper] = useField<string[]>(name);

  return (
    <MenuItem value={itemValue}>
      <ListItemIcon>
        <Checkbox
          color="primary"
          checked={meta.value.includes(itemValue)}
          onChange={() => helper.setValue(toggle(meta.value, itemValue))}
        />
      </ListItemIcon>
      <ListItemText
        primary={label}
        onClick={() => helper.setValue(toggle(meta.value, itemValue))}
      />
    </MenuItem>
  );
};

const toggle = <T,>(array: T[], value: T): T[] => {
  if (array.includes(value)) {
    return array.filter((v) => v !== value);
  } else {
    return [...array, value];
  }
};
