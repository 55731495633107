// https://github.com/react-qr-reader/react-qr-reader/blob/master/src/QrReader/index.tsx
import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser";
import { Result } from "@zxing/library";
import React, { MutableRefObject, useEffect, useRef } from "react";

export type OnResultFunction = (
  /**
   * The QR values extracted by Zxing
   */
  result?: Result | undefined | null,
  /**
   * The name of the exceptions thrown while reading the QR
   */
  error?: Error | undefined | null
) => void;

type QrReaderProps = {
  /**
   * Media track constraints object, to specify which camera and capabilities to use
   */
  constraints: MediaTrackConstraints;
  /**
   * Called when an error occurs.
   */
  onResult: OnResultFunction;
  /**
   * Property that represents the scan period
   */
  scanDelay?: number;
  /**
   * Property that represents the ID of the video element
   */
  videoId?: string;
};

export const QrReader: React.FC<QrReaderProps> = ({
  constraints: video,
  scanDelay: delayBetweenScanAttempts = 500,
  onResult,
  videoId = "video",
}) => {
  const controlsRef: MutableRefObject<IScannerControls | null> = useRef(null);

  useEffect(() => {
    if (!onResult || !isValidType(onResult, "onResult", "function")) {
      return;
    }

    if (!isValidType(video, "constraints", "object")) {
      return;
    }

    const codeReader = new BrowserQRCodeReader(undefined, {
      delayBetweenScanAttempts,
    });

    const onResultFn = (
      result: Result | null | undefined,
      error: Error | null | undefined
    ) => {
      onResult(result, error);
    };

    if (!isMediaDevicesSupported()) {
      const message =
        'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"';

      onResult(null, new Error(message));
    }

    codeReader
      .decodeFromConstraints({ video }, videoId, onResultFn)
      .then((controls: IScannerControls) => {
        controlsRef.current = controls;
      })
      .catch((error: Error) => onResult(null, error));

    return () => {
      controlsRef.current?.stop();
    };
  }, [delayBetweenScanAttempts, onResult, video, videoId]);

  return (
    <video
      muted
      id={videoId}
      style={{
        ...styles.video,
        transform: video?.facingMode === "user" && "scaleX(-1)",
      }}
    />
  );
};

const isMediaDevicesSupported = () => {
  const isMediaDevicesSupported =
    typeof navigator !== "undefined" && !!navigator.mediaDevices;

  if (!isMediaDevicesSupported) {
    console.warn(
      `[ReactQrReader]: MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"`
    );
  }

  return isMediaDevicesSupported;
};

const isValidType = (value: any, name: string, type: string) => {
  const isValid = typeof value === type;

  if (!isValid) {
    console.warn(
      `[ReactQrReader]: Expected "${name}" to be a of type "${type}".`
    );
  }

  return isValid;
};

const styles: any = {
  video: {
    top: 0,
    left: 0,
    width: "100%",
    display: "block",
    overflow: "hidden",
    transform: undefined,
  },
};
