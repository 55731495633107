import { SampleEditable, SampleJson, SampleListJson } from "../models/sample";
import { parseSample } from "../utils/sample";
import { useAPI } from "./useHooks";
import { useCallback } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import * as yup from "yup";

export interface SampleListQuery {
  sample_id?: string;
  site_owner?: string;
  report_to_phone_number?: string;
  site_address_street?: string;
  page: number;
  hd_water_supply_serial_number?: string;
  status?: string[];
}

function argsToQueryString(queryArgs: SampleListQuery): string {
  return Object.entries(queryArgs)
    .flatMap(([key, value]) =>
      Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
    )
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
}

export const useSamples = (
  params: SampleListQuery
): UseQueryResult<
  { samples: SampleListJson[]; pageData: string | null },
  unknown
> => {
  const queryParams = argsToQueryString(params);

  const { getRaw } = useAPI();

  return useQuery(["samples", queryParams], async () => {
    const response = await getRaw("/api/v1/labs/samples?" + queryParams);
    let pageData = response.headers.get("X-Pagination");
    if (pageData) pageData = JSON.parse(pageData);
    const responseJson = await response.json();
    return { samples: responseJson, pageData };
  });
};

export const useSample = (id: string): UseQueryResult<SampleJson, unknown> => {
  const sampleId = parseInt(id.split("-")[1]);
  const { getJson } = useAPI();
  return useQuery(["sample", id], () =>
    getJson<SampleJson>(`/api/v1/labs/samples/${sampleId}`)
  );
};

export const useGetSampleReportInfo = () => {
  const { getJson } = useAPI();

  return useCallback(
    (id: string): Promise<SendReportForm> => {
      const sampleId = parseInt(id.split("-")[1]);
      return getJson<SendReportForm>(
        `/api/v1/labs/samples/${sampleId}/report_info`
      );
    },
    [getJson]
  );
};
/*
 * Un Authed
 */
export const usePublicSample = (
  id: string
): UseQueryResult<SampleJson, unknown> => {
  return useQuery(
    ["publicSample", id],
    async () => {
      const response = await fetch(`/api/v1/samples/${id}`);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw responseJson;
      }
      return responseJson;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const collectSample = async (
  sample_id: string,
  sample: SampleEditable
): Promise<SampleJson> => {
  // Only a subset of Sample properties are accepted for this endpoint
  const { payment_amount, received_at_datetime, received_temp, ...rest } =
    sample;

  const response = await fetch(`/api/v1/samples/${sample_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rest),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return response.json();
};

export interface SendReportForm {
  email_subject: string;
  report_to_emails: string | null;
  report_cc_emails: string | null;
  cc_me: boolean;
  sample_id: string;
}

export function useSendReport() {
  const { putJsonGetJson } = useAPI();
  return useCallback(
    async ({
      sample_id,
      report_to_emails,
      cc_me,
      report_cc_emails,
      email_subject,
    }: SendReportForm) => {
      const params = {
        report_to_emails:
          report_to_emails
            ?.split(";")
            .map((e) => e.trim())
            .filter((e) => !!e.trim() && yup.string().email().isValidSync(e)) ??
          [],
        cc_me,
        report_cc_email:
          report_cc_emails
            ?.split(";")
            .map((e) => e.trim())
            .filter((e) => !!e.trim() && yup.string().email().isValidSync(e)) ??
          [],
        email_subject,
      };

      const sampleId = parseInt(sample_id.split("-")[1]);
      return putJsonGetJson(`/api/v1/labs/samples/${sampleId}/report`, params);
    },
    [putJsonGetJson]
  );
}

export function useDownloadReport(
  sampleId: string
): () => Promise<Response | null> {
  const { getRaw } = useAPI();
  const { sampleNumber } = parseSample(sampleId) ?? {};

  return useCallback(
    () =>
      sampleNumber
        ? getRaw(`/api/v1/labs/samples/${sampleNumber}/report`)
        : Promise.resolve(null),
    [getRaw, sampleNumber]
  );
}

export const useSaveSample2 = (sampleId: string) => {
  const { putJsonGetJson } = useAPI();
  const queryClient = useQueryClient();

  const { sampleNumber } = parseSample(sampleId) ?? {};

  return useMutation(
    (sample: SampleEditable) =>
      putJsonGetJson(`/api/v1/labs/samples/${sampleNumber}`, sample),
    {
      onSettled: () => queryClient.invalidateQueries(["sample", sampleId]),
    }
  );
};

export const useReceiveSample = (sampleId: string) => {
  const { postJsonGetJson } = useAPI();

  const queryClient = useQueryClient();
  const { sampleNumber } = parseSample(sampleId) ?? {};

  return useMutation(
    (sample: SampleEditable) => {
      const { results, ...rest } = sample;
      return postJsonGetJson(
        `/api/v1/labs/samples/${sampleNumber}/receive`,
        rest
      );
    },
    {
      onSettled: () => queryClient.invalidateQueries(["sample", sampleId]),
    }
  );
};
