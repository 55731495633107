import { LabSamplingContainerFull } from "./samplingContainers";
import { useAPI } from "./useHooks";
import { useMutation, useQuery } from "react-query";

export interface LabSamplingKit {
  id: string;

  name: string;
  description: string;

  lab_containers: string[];
}

export const useLabSamplingKits = () => {
  const { getJson } = useAPI();

  return useQuery(["lab-sampling-kits"], () =>
    getJson<LabSamplingKit[]>("/api/v1/labs/sampling_kits")
  );
};

export type LabSamplingKitFull = Omit<LabSamplingKit, "lab_containers"> & {
  lab_containers: LabSamplingContainerFull[];
};

export const useLabSamplingKit = (kitId: string) => {
  const { getJson } = useAPI();

  return useQuery(["lab-sampling-kits", kitId], () =>
    getJson<LabSamplingKitFull>(`/api/v1/labs/sampling_kits/${kitId}`)
  );
};

export const useSaveLabSamplingKit = (kitId: string) => {
  const { putJsonGetJson } = useAPI();

  return useMutation((data: LabSamplingKitFull) =>
    putJsonGetJson(`/api/v1/labs/sampling_kits/${kitId}`, data)
  );
};

export type UnsavedLabSamplingKit = Omit<LabSamplingKitFull, "id">;

export const useCreateLabSamplingKit = () => {
  const { postJsonGetJson } = useAPI();

  return useMutation((data: UnsavedLabSamplingKit) =>
    postJsonGetJson("/api/v1/labs/sampling_kits", data)
  );
};
