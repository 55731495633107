export function formatToMask(format: (string | RegExp)[]): Function {
  return function changeHandler(value: string): string {
    let result = "";
    for (let v = 0, f = 0; v < value.length && f < format.length; v++, f++) {
      // if the value matches the current format req
      if (value[v].match(format[f])) {
        result += value[v];
      } else if (
        // if the format value is spacer("-") and value matches next f value
        format[f] === "-" && // format is spacer
        f + 1 < format.length && // format isn't at end
        value[v].match(format[f + 1]) // value matches next non spacer format
      ) {
        result += `-${value[v]}`;
        f++;
      } else {
        // otherwise we no longer match and leave the loop
        break;
      }
    }

    return result;
  };
}

export const PHONE_MASK = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SAMPLE_ID_MASK = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
];

export const SAMPLE_ID_PLACEHOLDER = "xxx-xxxxx-x";

export const formatSampleId = formatToMask(SAMPLE_ID_MASK);

// Taken from Wikipedia: Damm_Algorithm
let table = [
  [0, 3, 1, 7, 5, 9, 8, 6, 4, 2],
  [7, 0, 9, 2, 1, 5, 4, 8, 6, 3],
  [4, 2, 0, 6, 8, 7, 1, 3, 5, 9],
  [1, 7, 5, 0, 9, 8, 3, 4, 2, 6],
  [6, 1, 2, 3, 0, 4, 5, 9, 7, 8],
  [3, 6, 7, 4, 2, 0, 9, 5, 8, 1],
  [5, 8, 6, 9, 7, 2, 0, 1, 3, 4],
  [8, 9, 4, 5, 3, 6, 2, 0, 1, 7],
  [9, 4, 3, 8, 6, 1, 7, 2, 0, 5],
  [2, 5, 8, 1, 4, 3, 6, 7, 9, 0],
];

function calcDamm(basenumber: string) {
  // Damm Algorithm
  let damm = 0;
  for (let i = 0; i < basenumber.length; ++i) {
    let num = parseInt(basenumber.charAt(i));
    damm = table[damm][num];
  }
  return damm;
}

export function isValidSampleId(sampleId: string | null): boolean {
  /**
   * split sampleId into its pieces
   * labId - sample# - checkDigit
   *
   * then use labId and sample# to calculate checkDigit
   * and ensure ours matches digit from probable sampleId
   */
  if (!sampleId || sampleId.length < 11) {
    return false;
  }

  let sampleIdPieces = sampleId.split("-");
  for (let piece of sampleIdPieces) {
    if (isNaN(parseInt(piece))) return false;
  }

  let idPart = sampleIdPieces[0].concat(sampleIdPieces[1]);
  let damm = calcDamm(idPart);
  return damm === parseInt(sampleIdPieces[2]);
}

export function isValidSampleId2(sampleId: string | null): boolean {
  /**
   * remove all non numeric digits
   * calcDamm(value||calcDamm(value)) === 0
   *
   * then use labId and sample# to calculate checkDigit
   * and ensure ours matches digit from probable sampleId
   */
  const sampleIdUnChunked = sampleId?.split("-").join("") ?? "";
  if (sampleIdUnChunked.length < 8) {
    return false;
  }

  if (!sampleIdUnChunked.match(/^\d*$/)) {
    console.log("Containers values not [0-9] or -");
    return false;
  }

  if (calcDamm(sampleIdUnChunked) !== 0) {
    console.log("Checksum is wrong", calcDamm(sampleIdUnChunked));
    return false;
  }

  return true;
}

export const parseSample = (
  sampleId: string
): null | {
  formattedSample: string;
  lab: number;
  sampleNumber: number;
  checksum: number;
} => {
  const formattedSample = formatSampleId(sampleId);

  if (!isValidSampleId(formattedSample)) {
    return null;
  }

  const sampleIdPieces = formattedSample.split("-");
  const lab = parseInt(sampleIdPieces[0]);
  const sampleNumber = parseInt(
    sampleIdPieces.slice(1, sampleIdPieces.length - 1).join("")
  );
  const checksum = parseInt(sampleIdPieces[sampleIdPieces.length - 1]);

  return {
    lab,
    formattedSample,
    sampleNumber,
    checksum,
  };
};

export const downloadBlobFile = async (response: Response): Promise<void> => {
  const blob = await response.blob();
  const fileName = response.headers.get("x-filename") ?? "report.pdf";

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  window.addEventListener("focus", () => URL.revokeObjectURL(link.href), {
    once: true,
  });
};
