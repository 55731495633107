import { useAPI } from "./useHooks";
import React from "react";
import { useQuery, useQueryClient } from "react-query";

export interface LabEmailGroup {
  id: string;
  name: string;
  sent_to: string;
  cc_to: string[];
}

export interface LabProfile {
  id: number;
  name: string;
  address_line_1: string;
  address_line_2: string;

  public_email: string;
  phone_number: string;

  certification_number: string | null;
  fax_number: string | null;
  signature_name: string;
  signature_title: string | null;
  website: string;

  has_kits?: boolean;

  settings?: {
    id: string;
    send_collection_confirmation_email: boolean;
  };

  email_groups: LabEmailGroup[];
}
export interface UserProfile {
  id: string;
  name: string;
  email: string;

  lab_id: number;
  lab: LabProfile;

  auth0_id: string;
}

interface UserContextType {
  userProfile?: UserProfile;
  refreshUser?: () => void;
}

export const UserContext = React.createContext<UserContextType>({});

export const useUser = (): UserProfile | null => {
  const context = React.useContext(UserContext);
  return context.userProfile ?? null;
};

export const useRefreshUser = (): (() => void) => {
  const context = React.useContext(UserContext);
  if (!context.refreshUser) {
    throw new Error('Can\'t use "useRefreshUser" outside of context');
  }
  return context.refreshUser;
};

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { getJson } = useAPI();

  const queryClient = useQueryClient();

  const { data: user } = useQuery<UserProfile>(
    "profile",
    () => getJson(`/api/v1/users/profile`),
    { staleTime: Infinity }
  );

  if (!user) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        userProfile: user,
        refreshUser: () => queryClient.invalidateQueries("profile"),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export type EditableLabProfile = Omit<LabProfile, "id" | "name">;
