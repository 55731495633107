import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledTableSection = styled.section`
  padding-bottom: 3rem;
`;

export const StyledTable = styled.table`
  border-spacing: 0;
  border-bottom: 1px solid black;

  tr {
    &:nth-child(odd) {
      background: #f0f0f0;
    }
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 8px 16px;
    border-bottom: 1px solid black;
    min-width: 150px;

    a {
      color: var(--theme-main-color);
    }
  }
`;

export const StyledPageNavDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin: 16px 16px;
  }
`;

export const PageNavigation: React.FC<{
  page: number;
  lastPage: number;
  setPage: (page: number) => void;
}> = ({ page, lastPage, setPage }) => (
  <StyledPageNavDiv>
    <Button
      variant="contained"
      onClick={() => setPage(page - 1)}
      disabled={page === 1}
    >
      Previous Page
    </Button>
    {`page ${page} of ${lastPage}`}
    <Button
      variant="contained"
      onClick={() => setPage(page + 1)}
      disabled={page === lastPage}
    >
      Next Page
    </Button>
  </StyledPageNavDiv>
);
