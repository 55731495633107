import { LoadingScreen } from "../../components/LoadingScreen";
import { useSample, useReceiveSample } from "../../hooks/samples";
import {
  sampleFormToEditableSample,
  SampleFormType,
  sampleToSampleForm,
} from "./SampleForm";
import CollectionDetails, {
  CollectionDetailsSectionSchema,
} from "./SampleForm/CollectionDetailsSection";
import IntakeDetails from "./SampleForm/IntakeDetails";
import ReportToDetails, { ReportToSchema } from "./SampleForm/ReportToDetails";
import SiteDetails, {
  SiteDetailsSectionSchema,
} from "./SampleForm/SiteDetails";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useMemo } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CancelButton,
  SaveButton,
  StyledActionsDiv,
} from "src/components/Form";

interface MatchParams {
  sampleId: string;
}

const ReceiveSampleSchema = SiteDetailsSectionSchema.concat(
  ReportToSchema
).concat(CollectionDetailsSectionSchema);

export const ReceiveSample: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  let { sampleId } = match.params;

  const history = useHistory();

  const { isLoading, isError, data, error } = useSample(sampleId);
  const formData = useMemo(() => data && sampleToSampleForm(data), [data]);

  const receiveSample2 = useReceiveSample(sampleId);

  const onSubmitSamples = useCallback(
    (values: SampleFormType, formikHelpers: FormikHelpers<SampleFormType>) =>
      toast.promise(
        receiveSample2
          .mutateAsync(sampleFormToEditableSample(values))
          .then(() => {
            history.replace("/samples");
          })
          .catch((e: any) => {
            formikHelpers.setErrors(e.errors);
            throw e;
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          }),
        {
          pending: "Saving",
          success: "Sample saved successfully",
          error: "Failed to save sample",
        }
      ),
    [history, receiveSample2]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (!data || !formData) {
    return null;
  }

  if (
    ["printed", "collected", "PRINTED", "COLLECTED", undefined].indexOf(
      data.status
    ) === -1
  ) {
    return <div>Sample already received by lab</div>;
  }

  return (
    <section>
      <Formik
        initialValues={formData}
        onSubmit={onSubmitSamples}
        validationSchema={ReceiveSampleSchema}
      >
        <Form autoComplete="on">
          <SiteDetails />
          <ReportToDetails />
          <CollectionDetails />
          <IntakeDetails />

          <StyledActionsDiv>
            <CancelButton />
            <SaveButton text="Receive" />
          </StyledActionsDiv>
        </Form>
      </Formik>
    </section>
  );
};
