import { useAPI } from "./useHooks";

const fieldsToParamString = (fields: Array<string>) =>
  fields.reduce((acc, cur) => `${acc}&fields=${cur}`, "");
// const dateToString = (date: Date) => `${}${}`

export function useGetExport() {
  const { getRaw } = useAPI();

  return async (
    start_date: string,
    end_date: string,
    fields: Array<string>
  ) => {
    let urlParams = `/api/v1/exports?start_date=${start_date}&end_date=${end_date}${fieldsToParamString(
      fields
    )}`;
    const response: Response = await getRaw(urlParams);
    let fileData = await response.text();
    let fileName = getAttachmentFilename(response.headers);
    return { fileData, fileName };
  };
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

const getAttachmentFilename = (headers: Headers) => {
  const disposition = headers.get("Content-Disposition");
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return null;
};
