import React from "react";
import styled from "styled-components";

interface SubHeaderProps {
  children: React.ReactNode;
}

export const SubHeader: React.FC<SubHeaderProps> = ({ children }) => {
  return <StyledSubHeader>{children}</StyledSubHeader>;
};

const StyledSubHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: var(--header-height);
  top: var(--header-height);

  padding: 8px 16px;
  background: #efefef;

  color: var(--theme-main-color);

  /* Otherwise the inputs scroll over the header  */
  z-index: 3;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;
