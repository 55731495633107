import { LoadingScreen } from "../../components/LoadingScreen";
import { useLabSamplingKits } from "../../hooks/samplingKits";
import AddIcon from "@mui/icons-material/Add";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const SamplingKitList: React.FC = () => {
  const { data } = useLabSamplingKits();

  if (!data) {
    return <LoadingScreen />;
  }

  return (
    <div style={{ width: "50rem" }}>
      {data.map((kit) => (
        <ListItem
          key={kit.id}
          button
          component={NavLink}
          to={`/sampling-kits/${kit.id}`}
        >
          <ListItemText primary={kit.name} />
        </ListItem>
      ))}
      <ListItem button component={NavLink} to={"/sampling-kits/new"}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="New" />
      </ListItem>
    </div>
  );
};

export default SamplingKitList;
