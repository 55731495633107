import {
  sampleFormToEditableSample,
  SampleFormType,
  sampleToSampleForm,
} from "./SampleForm";
import CollectionDetails, {
  CollectionDetailsSectionSchema,
} from "./SampleForm/CollectionDetailsSection";
import ReportToDetails, { ReportToSchema } from "./SampleForm/ReportToDetails";
import SiteDetails, {
  SiteDetailsSectionSchema,
} from "./SampleForm/SiteDetails";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  CancelButton,
  SaveButton,
  StyledActionsDiv,
} from "src/components/Form";
import { SelectField, SelectOption } from "src/components/FormComponents";
import { LoadingScreen } from "src/components/LoadingScreen";
import { StyledPublicPage } from "src/components/PublicPages";
import { TemplateRenderer } from "src/components/TemplateRenderer";
import { usePublicSample, collectSample } from "src/hooks/samples";
import { TemplateTypeEnum } from "src/models/template";

const CollectSampleSchema = SiteDetailsSectionSchema.concat(
  ReportToSchema
).concat(CollectionDetailsSectionSchema);

export const CollectSample: React.FC = () => {
  const match = useRouteMatch<{
    sampleId: string;
    labId: string;
  }>();
  const { push } = useHistory();
  const [isPreview, setIsPreview] = useState(false);

  const { labId, sampleId } = match.params;

  const { isLoading, isError, data, error } = usePublicSample(sampleId);

  const onSubmitCollectSamples = useCallback(
    (s: SampleFormType, helpers: FormikHelpers<SampleFormType>) => {
      if (isPreview) {
        return collectSample(sampleId, sampleFormToEditableSample(s)).then(
          (x) => {
            push(`/embed/collect-sample/${labId}/confirmation`);
            return x;
          }
        );
      } else {
        setIsPreview(true);
        helpers.setSubmitting(false);
      }
    },
    [isPreview, setIsPreview, push, labId, sampleId]
  );

  const formData = useMemo(() => data && sampleToSampleForm(data), [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (isError) {
    return <div>{JSON.stringify(error)}</div>;
  }
  if (!data || !formData) {
    return null;
  }
  if (
    data.status === undefined ||
    !["printed", "collected", "PRINTED", "COLLECTED"].includes(data.status)
  ) {
    return <div>Sample already received by lab</div>;
  }

  return (
    <>
      <StyledPublicPage>
        <TemplateRenderer
          sampleId={sampleId}
          templateType={TemplateTypeEnum.FORM_HEADER}
        />
      </StyledPublicPage>
      <section>
        <Formik
          initialValues={formData}
          onSubmit={onSubmitCollectSamples}
          validationSchema={CollectSampleSchema}
        >
          {(x) => (
            <Form autoComplete="on">
              {!isPreview && (
                <SelectField
                  id="well-type"
                  name="custom_fields.well_type"
                  label="Select Well Type"
                  options={FormTypeSelectionList}
                />
              )}
              {x.values.custom_fields?.well_type && (
                <>
                  <SiteDetails isPreview={isPreview} />
                  <ReportToDetails isPreview={isPreview} />
                  <CollectionDetails isPreview={isPreview} />
                </>
              )}
              <StyledActionsDiv>
                {isPreview ? (
                  <CancelButton
                    onClick={() => setIsPreview(false)}
                    text="Edit"
                  />
                ) : (
                  <CancelButton />
                )}
                <SaveButton text={!isPreview ? "Preview" : "Submit"} />
              </StyledActionsDiv>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

const FormTypeSelectionList: SelectOption<string>[] = [
  { id: "private", name: "Private Residence" },
  { id: "public", name: "Public Water Supply" },
];
