import { useAPI } from "./useHooks";
import { useQuery } from "react-query";

export interface LabTest {
  id: string;

  name: string;
}

export const useLabTests = () => {
  const { getJson } = useAPI();

  return useQuery(["lab-testss"], () =>
    getJson<LabTest[]>("/api/v1/labs/tests")
  );
};
