import { FieldProps, SX } from ".";
import { formatSampleId, isValidSampleId } from "../../utils/sample";
import { QrReader } from "../QrReader";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import { Result as QrResult } from "@zxing/library";
import { useField } from "formik";
import React, { useCallback, useState } from "react";

type SampleIdFieldProps = FieldProps & {
  showQRScanner?: boolean;
};

const SampleIdField: React.FC<SampleIdFieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth, showQRScanner } = props;
  const [field, meta, helpers] = useField({
    name,
    validate: requireValidSampleId,
  });

  const [isQRScannerUp, setIsQRScannerUp] = useState(false);

  console.assert(
    field.value !== undefined,
    `[SampleIdField][${name}] Value must not be set`
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target as HTMLInputElement;

      const maskedValue = formatSampleId(value);

      helpers.setValue(maskedValue, true);
    },
    [helpers]
  );

  const closeQRScanner = useCallback(() => {
    setIsQRScannerUp(false);
  }, [setIsQRScannerUp]);

  const onBarcodeScanned = useCallback(
    (barCode: string) => {
      helpers.setValue(barCode);
      setIsQRScannerUp(false);
    },
    [helpers, setIsQRScannerUp]
  );

  const openQRScanner = useCallback(() => {
    setIsQRScannerUp(true);
  }, [setIsQRScannerUp]);

  const inputProps = {
    endAdornment:
      !disabled && showQRScanner ? (
        <InputAdornment position="end">
          <IconButton
            aria-label={"Scan QR Code"}
            edge="end"
            onClick={openQRScanner}
          >
            <CameraAltIcon />
          </IconButton>
        </InputAdornment>
      ) : null,
  };

  return (
    <>
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={field.value}
        onBlur={field.onBlur}
        onChange={onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        sx={SX}
        InputProps={inputProps}
      />
      <ScanQRCodeDialog
        isOpen={isQRScannerUp}
        requestClose={closeQRScanner}
        onBottleBarCode={onBarcodeScanned}
      />
    </>
  );
};

const requireValidSampleId = (value: string) => {
  if (!value) return;
  if (!isValidSampleId(value)) {
    return "Invalid Sample Id";
  }
};

const constraints: MediaTrackConstraints = {
  facingMode: "environment",
  zoom: 1,
  width: 640,
  height: 480,
} as any;

const ScanQRCodeDialog: React.FC<{
  isOpen: boolean;
  requestClose: () => void;
  onBottleBarCode: (barcode: string) => void;
}> = ({ isOpen, requestClose, onBottleBarCode }) => {
  const onResult = useCallback(
    (result: QrResult | null | undefined) => {
      if (!result) {
        return;
      }

      const maskedValue = formatSampleId(result.getText());
      if (isValidSampleId(maskedValue)) {
        onBottleBarCode(maskedValue);
      }
    },
    [onBottleBarCode]
  );

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>Scan Bottle QR Code</DialogTitle>

      <DialogContent>
        <QrReader constraints={constraints} onResult={onResult} />
      </DialogContent>

      <DialogActions>
        <Button onClick={requestClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SampleIdField;
