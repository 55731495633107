import { TemplateTypeEnum } from "../models/template";
import { useAPI } from "./useHooks";
import { useMutation, useQuery } from "react-query";

export function useGetTemplateForLabOrSample() {
  return async function (
    labOrSampleId: number | string,
    templateType: TemplateTypeEnum
  ) {
    const response = await fetch(
      `/api/v1/templates/${labOrSampleId}/${templateType}`
    );
    if (response.status !== 200) {
      throw await response.json();
    }
    return await response.text();
  };
}

export type TemplateKey = "landing";

export const useTemplate = (templateKey: TemplateKey) => {
  const { getText } = useAPI();

  return useQuery(["template", templateKey], () =>
    getText(`/api/v1/labs/templates/${templateKey}`)
  );
};

export const useSaveTemplate = (templateKey: TemplateKey) => {
  const { putRawRequestTextResponse } = useAPI();

  return useMutation(
    (newValue: string) =>
      putRawRequestTextResponse(
        `/api/v1/labs/templates/${templateKey}`,
        newValue
      ),
    {}
  );
};
