import {
  sampleFormToEditableSample,
  SampleFormType,
  sampleToSampleForm,
} from "./SampleForm";
import CollectionDetails, {
  CollectionDetailsSectionSchema,
} from "./SampleForm/CollectionDetailsSection";
import DownloadReportButton from "./SampleForm/DownloadReportButton";
import EmailReportButton from "./SampleForm/EmailReportButton";
import IntakeDetails from "./SampleForm/IntakeDetails";
import ReportToDetails, { ReportToSchema } from "./SampleForm/ReportToDetails";
import ReportsSent from "./SampleForm/ReportsSent";
import ResultsForm from "./SampleForm/ResultsForm";
import SiteDetails, {
  SiteDetailsSectionSchema,
} from "./SampleForm/SiteDetails";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SaveButton,
  StyledActionsDiv,
  StyledFormSectionRows,
} from "src/components/Form";
import { CheckboxField, TextField } from "src/components/FormComponents";
import { LoadingScreen } from "src/components/LoadingScreen";
import {
  SendReportForm,
  useGetSampleReportInfo,
  useSample,
  useSaveSample2,
} from "src/hooks/samples";
import { useSendReport } from "src/hooks/samples";
import { useUser } from "src/hooks/user";
import { SampleJson } from "src/models/sample";

const ReceiveSampleSchema = SiteDetailsSectionSchema.concat(
  ReportToSchema
).concat(CollectionDetailsSectionSchema);

export const EditSample: React.FC = () => {
  const match = useRouteMatch<{
    sampleId: string;
  }>();
  const { sampleId } = match.params;

  const [reportModal, setReportModal] = useState<SendReportForm | null>(null);
  const closeReportModal = useCallback(
    () => setReportModal(null),
    [setReportModal]
  );
  const openReportModal = useCallback(
    (data: any) => setReportModal(data),
    [setReportModal]
  );

  const saveSample2 = useSaveSample2(sampleId);
  const { isLoading, isError, data, error } = useSample(sampleId);
  const formData = useMemo(() => data && sampleToSampleForm(data), [data]);

  const onSubmitSamples = useCallback(
    (values: SampleFormType, formikHelpers: FormikHelpers<SampleFormType>) =>
      toast.promise(
        saveSample2
          .mutateAsync(sampleFormToEditableSample(values))
          .catch((e: any) => {
            formikHelpers.setErrors(e.errors);
            throw e;
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          }),
        {
          pending: "Saving",
          success: "Sample saved successfully",
          error: "Failed to save sample",
        }
      ),
    [saveSample2]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>{JSON.stringify(error)}</div>;
  }
  if (!data || !formData) {
    return null;
  }

  return (
    <>
      <section>
        <Formik
          initialValues={formData}
          onSubmit={onSubmitSamples}
          validationSchema={ReceiveSampleSchema}
        >
          <Form autoComplete="on">
            <SiteDetails />
            <ReportToDetails />
            <CollectionDetails />
            <IntakeDetails />
            <ResultsForm />

            <StyledActionsDiv>
              <EmailReportButton sendReport={openReportModal} />
              <DownloadReportButton />
              <SaveButton text="Save" />
            </StyledActionsDiv>

            <ReportsSent />
          </Form>
        </Formik>
      </section>

      {reportModal ? (
        <ReportModal
          isOpen={!!reportModal}
          sampleId={sampleId}
          sample={data}
          closeReportModal={closeReportModal}
        />
      ) : null}
    </>
  );
};

const ReportModal: React.FC<{
  isOpen: boolean;
  sampleId: string;
  sample: SampleJson;
  closeReportModal: () => void;
}> = ({ isOpen, sampleId, sample, closeReportModal }) => {
  const sendReport = useSendReport();
  const getReportInfo = useGetSampleReportInfo();

  const [reportData, setReportData] = useState<null | SendReportForm>(null);

  const handleSendReport = useCallback(
    async (values: SendReportForm) =>
      toast.promise(sendReport(values), {}).then(() => closeReportModal()),
    [closeReportModal, sendReport]
  );

  useEffect(() => {
    getReportInfo(sampleId).then((report_form) => setReportData(report_form));
  }, [sampleId, isOpen, setReportData, getReportInfo]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeReportModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={false}
      fullWidth={true}
      maxWidth={"lg"}
    >
      {reportData ? (
        <Formik
          initialValues={{
            email_subject: reportData.email_subject,
            report_to_emails: reportData.report_to_emails,
            report_cc_emails: reportData.report_cc_emails,
            cc_me: false,
            sample_id: sampleId,
          }}
          onSubmit={(values) => handleSendReport(values)}
        >
          <Form autoComplete="off">
            <StyledFormSectionRows>
              <DialogTitle>Confirm Recipients and Email Report</DialogTitle>
              <DialogContent>
                <TextField
                  label="Email Subject"
                  name="email_subject"
                  id="email_subject"
                  fullWidth={true}
                />
                <br />
                <br />
                <SelectGroup sampleData={sample} />
                <br />
                <br />
                <TextField
                  label="Email to"
                  name="report_to_emails"
                  id="report_to_emails"
                  fullWidth={true}
                />
                <br />
                <br />
                <TextField
                  label="CC to"
                  name="report_cc_emails"
                  id="report_cc_emails"
                  placeholder="a@mail.com; b@mail.com, ..."
                  fullWidth={true}
                />
                <br />
                <br />
                <CheckboxField
                  label="Send Copy To Myself"
                  name={`cc_me`}
                  id="cc_me"
                />
                <br />
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" type="submit">
                  Email Report
                </Button>
              </DialogActions>
            </StyledFormSectionRows>
          </Form>
        </Formik>
      ) : null}
    </Dialog>
  );
};

const SelectGroup: React.FC<{ sampleData: SampleJson }> = (props) => {
  const [group, setGroup] = useState("_form");

  const { setValues, values } = useFormikContext<SendReportForm>();

  const user = useUser();

  if (!user?.lab) {
    throw new Error("Can't be used outside of user context");
  }

  const changeGroup = (event: unknown, value: string) => {
    const report_to_emails_array =
      props.sampleData.report_to_email
        ?.split(";")
        .map((email: string) => email.trim())
        .filter((x) => !!x) ?? [];
    const report_cc_emails_array =
      props.sampleData.report_cc_email
        ?.split(";")
        .map((email: string) => email.trim())
        .filter((x) => !!x) ?? [];

    const { cc_to = [], sent_to } =
      user.lab.email_groups.find((x) => x.id === value) ?? {};
    const sent_to_arry = sent_to ? [sent_to] : [];

    setValues({
      ...values,
      report_to_emails: report_to_emails_array.concat(sent_to_arry).join("; "),
      report_cc_emails: report_cc_emails_array.concat(cc_to).join("; "),
    });

    setGroup(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={group}
      exclusive
      onChange={changeGroup}
    >
      <ToggleButton value="_form">Form</ToggleButton>
      {user.lab.email_groups.map((g) => (
        <ToggleButton key={g.id} value={g.id}>
          {g.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
