// TODO: When we support multiple fields reports
//       this can be moved to an api call

export enum ExportFields {
  SAMPLE_ID = "sample_id",
  SITE_OWNER = "site_owner",
  SITE_ADDRESS = "site_address",
  ANALYSIS = "analysis",
  PAYMENT_AMOUNT = "payment_amount",
  PAYMENT_METHOD = "payment_method",
  REPORT_TO_NAME = "report_to_name",
  REPORT_TO_ADDRESS = "report_to_address",
}

export const DEFAULT_EXPORT = [
  ExportFields.SAMPLE_ID,
  ExportFields.REPORT_TO_NAME,
  ExportFields.REPORT_TO_ADDRESS,
  ExportFields.ANALYSIS,
  ExportFields.PAYMENT_AMOUNT,
  ExportFields.PAYMENT_METHOD,
];
