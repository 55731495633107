import { useAPI } from "./useHooks";

export const useCreateAndGetLabels = () => {
  const { postJsonGetRaw } = useAPI();

  return (props: {
    count: number;
    sample_kit?: string;
    bottle1?: boolean;
    bottle2?: boolean;
    bag?: boolean;
  }) =>
    postJsonGetRaw(`/api/v1/labs/samples/label`, {
      count: props.count,
      labels: _getLabelNames(props),
      sample_kit: props.sample_kit,
    });
};

export const useGetExistingLabels = () => {
  const { postJsonGetRaw } = useAPI();

  return (props: {
    sampleId: string;
    bottle1?: boolean;
    bottle2?: boolean;
    bag?: boolean;
  }) =>
    postJsonGetRaw(`/api/v1/labs/samples/${props.sampleId}/label`, {
      labels: _getLabelNames(props),
    });
};

function _getLabelNames(props: {
  bottle1?: boolean;
  bottle2?: boolean;
  bag?: boolean;
}) {
  const ret = [];
  if (props.bottle1) {
    ret.push("bottle1");
  }
  if (props.bottle2) {
    ret.push("bottle2");
  }
  if (props.bag) {
    ret.push("bag");
  }
  return ret;
}
