import React from "react";
import styled from "styled-components";

interface NavProps {
  children: React.ReactNode;
}

export const Nav: React.FC<NavProps> = ({ children }) => {
  return <StyledNav>{children}</StyledNav>;
};

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 100%;
  width: var(--nav-width);

  padding: 16px 0;

  a {
    font-size: 1.6rem;

    &.active {
      font-weight: 700 !important;
      color: var(--theme-main-color) !important;
      background: #efefef;
    }
  }
`;
