import {
  StyledActionsDiv,
  StyledFormHeader,
  StyleReset,
} from "../../components/Form";
import { useSaveTemplate, useTemplate } from "../../hooks/templates";
import { TemplateTypeEnum } from "../../models/template";
import { AppBar, Button, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";

export const Templates: React.FC = () => {
  const { templateKey } = useParams<{ templateKey: string }>();
  const history = useHistory();

  const { data } = useTemplate(templateKey as any);
  const saveTemplate = useSaveTemplate(templateKey as any);

  const [value, setValue] = useState<string | null>(null);
  useEffect(() => {
    if (data !== undefined) {
      setValue(data);
    }
  }, [data, setValue]);

  if (value === null) {
    return null;
  }

  return (
    <>
      <StyledFormHeader>Sample Collection Templates</StyledFormHeader>
      <AppBar position="static" color="transparent">
        <Tabs
          value={templateKey}
          onChange={(e, index) => history.push(index)}
          indicatorColor="primary"
        >
          <Tab label="Start Page" value={TemplateTypeEnum.LANDING} />
          <Tab label="Form Header" value={TemplateTypeEnum.FORM_HEADER} />
          <Tab label="Web Confirmation" value={TemplateTypeEnum.CONFIRMATION} />
          <Tab
            label="Confirmation Email"
            value={TemplateTypeEnum.CONFIRMATION_EMAIL}
          />
          <Tab label="Report Email" value={TemplateTypeEnum.REPORT_EMAIL} />
        </Tabs>
      </AppBar>
      <div style={{ height: "16px" }} />
      <div>
        <StyleReset>
          <ReactQuill
            modules={modules}
            formats={formats}
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </StyleReset>
        <StyledActionsDiv>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveTemplate.mutateAsync(value)}
          >
            Save
          </Button>
        </StyledActionsDiv>
      </div>

      <div>
        <h4>Preview</h4>
        <div
          style={{ maxWidth: "600px" }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
    </>
  );
};

/**
 * https://github.com/zenoamaro/react-quill
 */
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
