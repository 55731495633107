import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import styled from "styled-components";

export const StyledFormHeader = styled.h1`
  border-bottom: 1px solid rgba(100, 100, 100, 0.4);
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const StyledFormSubheader = styled.h2`
  margin-left: 16px;
  margin-top: 16px;

  label {
    margin-left: 16px;
  }
`;

export const StyledFormSectionRows = styled.section`
  max-width: 1200px;
  min-height: 75px;
  margin-bottom: 32px;

  > *,
  > .MuiFormControl-root {
    margin: 8px 16px;
  }

  .MuiFormControl-fullWidth {
    width: 100%; !important;
  }

  @media (max-width: 900px) {
    /* at 800px or less we drop to 2 rows 
      and fill screen width */
    & > * {
      width: calc(50% - 32px);
    }
    /* Checkboxes expose labels as outer element
        we want them to be qtr width */
    & > label {
      width: calc(25% - 32px);
    }
    & > .label-full-width {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    /* at 600px or less we drop to 1 row 
        and fill screen width */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    & > * {
      width: 100%;
    }
    /* Checkboxes expose labels as outer element
        we want them to be half width */
    & > label {
      width: calc(50% - 32px);
    }
    & > .label-full-width {
      width: 100%;
    }
  }
  /* Gets rid of a weird black line that we have on hover of a picker element */
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: var(--theme-main-color);
  }
`;

export const StyledActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  padding-bottom: 32px;
  & > * {
    margin-left: 16px;
  }
`;

// To get rid of global styles we dont want the editor using
export const StyleReset = styled.div`
  img {
    width: unset;
    height: unset;
  }

  max-width: 550px;
`;

export const StyledFormGroupRow = styled.div`
  border: 1px solid rgba(100, 100, 100, 0.4);
  border-radius: 3px;
  margin-bottom: 8px;
  padding: 8px;

  display: flex;
  flex-direction: row;

  .field-content {
    margin-right: 8px;
    flex-grow: 1;

    > div {
      margin-right: 8px;
    }
  }

  .field-remove {
    flex-grow: 0;
  }
`;

interface ButtonProps {
  type?: "primary" | "secondary";
  text?: string;
  onClick?: () => void;
}

const getButtonParams = (
  type: "primary" | "secondary"
): { variant: "text" | "outlined" | "contained" } => {
  switch (type) {
    case "primary":
      return {
        variant: "contained",
      };
    case "secondary":
      return {
        variant: "text",
      };
  }
};

export const SaveButton: React.FC<ButtonProps> = ({
  type = "primary",
  text = "Save",
  onClick,
}) => {
  const { dirty, isSubmitting } = useFormikContext();

  return (
    <Button
      color="primary"
      {...getButtonParams(type)}
      type={onClick ? "button" : "submit"}
      disabled={!dirty || isSubmitting}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const CreateButton: React.FC<ButtonProps> = ({
  type = "primary",
  text = "Create",
  onClick,
}) => {
  const { dirty, isSubmitting } = useFormikContext();

  return (
    <Button
      color="primary"
      {...getButtonParams(type)}
      type={onClick ? "button" : "submit"}
      disabled={!dirty || isSubmitting}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const CancelButton: React.FC<ButtonProps> = ({
  type = "secondary",
  text = "Cancel",
  onClick,
}) => {
  const { dirty, isSubmitting } = useFormikContext();

  return (
    <Button
      color="primary"
      {...getButtonParams(type)}
      type={onClick ? "reset" : "button"}
      disabled={!dirty || isSubmitting}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const FormStatus: React.FC = () => {
  const x = useFormikContext();
  return (
    <>
      <div>{JSON.stringify(x.errors)}</div>
      <div>{JSON.stringify(x.values)}</div>
    </>
  );
};
