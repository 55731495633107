import { Button } from "@mui/material";
import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDownloadReport } from "src/hooks/samples";
import { downloadBlobFile } from "src/utils/sample";

const DownloadReportButton: React.FC = () => {
  const match = useRouteMatch<{
    sampleId: string;
  }>();
  const { sampleId } = match.params;
  const downloadReport = useDownloadReport(sampleId);

  const onClick = useCallback(async () => {
    const blob = await downloadReport();
    if (blob) {
      downloadBlobFile(blob);
    }
  }, [downloadReport]);

  return (
    <Button color="primary" variant="outlined" onClick={onClick}>
      Download Report
    </Button>
  );
};

export default DownloadReportButton;
