import { StyledFormHeader } from "../../../components/Form";
import { LoadingScreen } from "../../../components/LoadingScreen";
import {
  PageNavigation,
  StyledTable,
  StyledTableSection,
} from "../../../components/Tables";
import { SampleListQuery, useSamples } from "../../../hooks/samples";
import { SampleListJson } from "../../../models/sample";
import { SampleFilterForm } from "./filterForm";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const initialFilterValues: SampleListQuery = {
  sample_id: "",
  site_owner: "",
  site_address_street: "",
  report_to_phone_number: "",
  status: ["RECEIVED", "REPORTED"],
  page: 1,
  hd_water_supply_serial_number: "",
};

const LOCALSTORAGE_KEY = "ListSamples-Query";

export const Samples: React.FC = () => {
  const [params, setParams] = useState(initialFilterValues);

  const { data, error, isFetching } = useSamples(params);
  const { samples: sampleData, pageData } = data || {
    samples: [],
    pageData: undefined,
  };

  const { page, last_page } = (pageData as any) || {
    page: undefined,
    last_page: undefined,
  };

  useEffect(() => {
    const saved = localStorage.getItem(LOCALSTORAGE_KEY);
    if (saved) {
      const savedParsed = JSON.parse(saved);
      setParams({
        ...initialFilterValues,
        ...savedParsed,
      });
    }
  }, []);

  const updateParams = (p: SampleListQuery) => {
    const { status } = p;
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({ status }));

    setParams(p);
  };

  const setPage = (page: number) => {
    setParams((current) => ({ ...current, page }));
  };

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <>
      <SampleFilterForm params={params} onParamsChange={updateParams} />
      <StyledTableSection>
        <StyledFormHeader>Search Results</StyledFormHeader>

        {isFetching ? (
          <LoadingScreen />
        ) : (
          <>
            <PageNavigation
              page={page}
              lastPage={last_page}
              setPage={setPage}
            />
            <StyledTable>
              <thead>
                <tr>
                  {tableHeaders.map((header) => {
                    return <th key={header.label}>{header.label}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {(sampleData || []).map((sample: SampleListJson) => {
                  return (
                    <tr key={`row ${sample.sample_id}`}>
                      {tableHeaders.map((column) => {
                        return (
                          <td key={`col ${sample.sample_id} ${column.label}`}>
                            {column.isId === true ? (
                              <Link
                                to={`/samples/edit/${column.getValue(sample)}`}
                              >
                                {column.getValue(sample)}
                              </Link>
                            ) : (
                              column.getValue(sample)
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
            <PageNavigation
              page={page}
              lastPage={last_page}
              setPage={setPage}
            />
          </>
        )}
      </StyledTableSection>
    </>
  );
};

/**
 * label is the column name, getValue takes in a sample and returns the value for that column
 */
const tableHeaders = [
  {
    label: "Sample ID",
    getValue: (data: SampleListJson) => data.sample_id,
    isId: true,
  },
  { label: "Site Owner", getValue: (data: SampleListJson) => data.site_owner },
  {
    label: "Site Address",
    getValue: (data: SampleListJson) => data.site_address_street,
  },
  {
    label: "Phone",
    getValue: (data: SampleListJson) => data.report_to_phone_number,
  },
  {
    label: "Status",
    getValue: (data: SampleListJson) => data.status || "printed",
  },
  {
    label: "WSSN",
    getValue: (data: SampleListJson) => data.hd_water_supply_serial_number,
  },
  {
    label: "Date Received",
    getValue: (data: SampleListJson) =>
      data.received_at_datetime
        ? format(new Date(data.received_at_datetime), "MM/dd/yyyy")
        : null,
  },
  {
    label: "Report Last Sent",
    getValue: (data: SampleListJson) =>
      data.report_last_sent_datetime
        ? format(new Date(data.report_last_sent_datetime), "MM/dd/yyyy")
        : null,
  },
  {
    label: "Report Last Sent To",
    getValue: (data: SampleListJson) => {
      const sent_to = data.report_last_sent_to_email ?? "";
      const cc_to = data.report_last_sent_cc_email ?? [];

      if (cc_to.length === 0) {
        return sent_to;
      }

      return `${sent_to} cc: ${cc_to.join(", ")}`;
    },
  },
];
