import { Header } from "../components/Header";
import { LoginButton } from "../components/LoginButton";
import { Nav } from "../components/Navigation";
import { SampleSelection } from "../components/SampleSelection";
import { SubHeader } from "../components/SubHeader";
import { useUser } from "../hooks/user";
import { theme } from "../theme";
import { isValidSampleId } from "../utils/sample";
import { Exports } from "../views/exports/Exports";
import { Labels } from "../views/labels/Labels";
import { SampleKitLabels } from "../views/labels/SampleKitsLabels";
import { EditSample } from "../views/samples/EditSample";
import { Samples } from "../views/samples/ListSamples";
import { ReceiveSample } from "../views/samples/ReceiveSample";
import SamplingKitCreate from "../views/samplingKits/SamplingKitCreate";
import SamplingKitEdit from "../views/samplingKits/SamplingKitEdit";
import SamplingKitList from "../views/samplingKits/SamplingKitList";
import { Settings } from "../views/settings/Settings";
import TemplateRedirect from "../views/templates/TemplateRedirect";
import { Templates } from "../views/templates/Templates";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Drawer,
  ListItem,
  ListItemText,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Switch,
  Route,
  NavLink,
  Redirect,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

export const PrivateApp: React.FC = () => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const toggleNav = () => setIsNavOpen((current) => !current);

  const location = useLocation();
  const [sampleId, setSampleId] = useState<string | null>(null);
  useEffect(() => {
    let sampleId = null;
    let path = location.pathname;
    if (path.includes("sample")) {
      sampleId = path.split("/").pop() ?? null;
    }
    isValidSampleId(sampleId) ? setSampleId(sampleId) : setSampleId(null);
  }, [location]);

  const user = useUser();

  useLayoutEffect(() => {
    // this sets the css variable to whatever our theme is using
    let el = document.querySelector("html");
    el?.style.setProperty("--theme-main-color", theme.palette.primary.main);
  }, []);

  // const isAuthenticated = true;

  if (isAuthLoading || !user) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" size={50} />
      </Backdrop>
    );
  }

  return (
    <>
      <Header>
        <LogoImg
          src={user?.lab.id ? `/api/v1/labs/${user?.lab.id}/logo` : undefined}
          onClick={toggleNav}
        />
        <LoginButton />
      </Header>
      <SubHeader>
        <SubHeaderContent>
          <Button variant="text" onClick={toggleNav}>
            Menu {isNavOpen ? "-" : "+"}
          </Button>
          {sampleId ? (
            <div>
              <span className="span-label">Current Sample: </span>
              {sampleId}
            </div>
          ) : null}
        </SubHeaderContent>
      </SubHeader>
      <ContentContainer isAuthenticated={isAuthenticated}>
        <Drawer open={isNavOpen} anchor="left" variant="persistent">
          <Nav>
            <ListItem button component={NavLink} to={"/customer-view"}>
              <ListItemText primary={"Customer View"} />
            </ListItem>
            <ListItem button component={NavLink} to="/samples" exact>
              <ListItemText primary={"Search Samples"} />
            </ListItem>
            <ListItem button component={NavLink} to="/samples/receive">
              <ListItemText primary={"Receive Samples"} />
            </ListItem>
            <ListItem button component={NavLink} to="/labels">
              <ListItemText primary={"Print Labels"} />
            </ListItem>
            <ListItem button component={NavLink} to="/sampling-kits">
              <ListItemText primary="Edit Sampling Kits" />
            </ListItem>
            <ListItem button component={NavLink} to="/templates">
              <ListItemText primary={"Edit Templates"} />
            </ListItem>
            <ListItem button component={NavLink} to="/Exports">
              <ListItemText primary={"Export Data"} />
            </ListItem>
            <ListItem button component={NavLink} to="/settings">
              <ListItemText primary="Settings" />
            </ListItem>
          </Nav>
        </Drawer>
        <div className={`drawer-spacer ${isNavOpen ? "open" : "closed"}`}>
          {/* This is empty and we make it grow so 
            the drawer doesn't cover the main content except on small screens*/}
        </div>
        <MainContent isAuthenticated={isAuthenticated}>
          <Switch>
            {/*Order is important - PublicConfirmation needs to come before CollectSample else PublicConfirmation will not work*/}
            <Route exact path="/customer-view">
              <iframe
                src={`/embed?labId=${user?.lab_id}`}
                title="Customer sample collection view"
                width="100%"
                height="100%"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "black",
                }}
              ></iframe>
            </Route>

            <Route exact path="/samples" component={Samples} />

            <Route exact path="/samples/receive">
              <SampleSelection nextPath="/samples/receive/:sampleId" />
            </Route>

            <Route
              path="/samples/receive/:sampleId"
              component={ReceiveSample}
            />

            <Route path="/samples/edit/:sampleId" component={EditSample} />
            <Route path="/labels">
              {user.lab.has_kits ? <SampleKitLabels /> : <Labels />}
            </Route>

            <Route exact path="/sampling-kits" component={SamplingKitList} />
            <Route path="/sampling-kits/new" component={SamplingKitCreate} />
            <Route path="/sampling-kits/:kitId" component={SamplingKitEdit} />

            <Route exact path="/templates" component={TemplateRedirect} />
            <Route exact path="/templates/:templateKey" component={Templates} />

            <Route path="/exports" component={Exports} />
            <Route path="/settings" component={Settings} />

            <Redirect to="/customer-view" />
          </Switch>
        </MainContent>
      </ContentContainer>
    </>
  );
};

interface IsAuthenticated {
  isAuthenticated: boolean;
}

const LogoImg = styled.img`
  max-height: 70px;
  width: auto;
  max-width: 180px;
  filter: grayscale(1) brightness(10000);
`;

const ContentContainer = styled.div<IsAuthenticated>`
  position: relative; // relative position to let us use absolute positioning of child elements
  display: flex;
  width: 100%;
  height: ${(props) =>
    `calc(100vh - var(--header-height) * ${props.isAuthenticated ? 2 : 1})`};
  justify-content: flex-start;

  /* Css overriding the drawer to give it a contained appearance */
  .MuiDrawer-paper {
    height: calc(100vh - var(--header-height) * 2);
    top: calc(var(--header-height) * 2);
  }

  .drawer-spacer {
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.closed {
      width: 0px;
    }
    &.open {
      /* the 32px comes from the padding on the main container */
      width: calc(var(--nav-width) + 32px);
      @media (max-width: 600px) {
        width: 0px;
      }
    }
  }
`;

const MainContent = styled.main<IsAuthenticated>`
  position: relative; // relative position to let us use absolute positioning of child elements
  width: 100%;
  height: 100%;

  padding: 16px 32px;
  margin-top: ${(props) =>
    `calc(var(--header-height) * ${props.isAuthenticated ? 2 : 1})`};
`;

const SubHeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .span-label {
    color: black;
  }
`;
