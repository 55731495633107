import { format } from "date-fns";
import { useField } from "formik";
import { groupBy, uniq } from "lodash";
import React from "react";
import { StyledFormHeader, StyledFormSectionRows } from "src/components/Form";
import {
  CheckboxMultiField,
  DateTimeField,
  TextField,
} from "src/components/FormComponents";
import {
  FormPreviewHeader,
  FormPreviewSection,
  FormPreviewText,
} from "src/components/FormPreview";
import { SampleAvailableTests } from "src/models/sample";
import * as Yup from "yup";

export interface CollectionDetailsFormSectionRow {
  available_test_names: string[];
  selected_test_names: string[];
  collected_at_location: string;
  collected_by_name: string;
  collected_at_datetime: string;
}

export interface CollectionDetailsFormSection {
  collection_details: CollectionDetailsFormSectionRow[];
}

const CollectionDetailsSchema = Yup.object({
  selected_test_names: Yup.array().of(Yup.string()),
  collected_by_name: Yup.string().when("selected_test_names", {
    is: (v: unknown[]) => v.length > 0,
    then: (schema) => schema.required("Required"),
  }),
  collected_at_datetime: Yup.string()
    .nullable()
    .when("selected_test_names", {
      is: (v: unknown[]) => v.length > 0,
      then: (schema) => schema.nullable().required("Required"),
    }),
  collected_at_location: Yup.string().when("selected_test_names", {
    is: (v: unknown[]) => v.length > 0,
    then: (schema) => schema.required("Required"),
  }),
});

export const CollectionDetailsSectionSchema = Yup.object().shape({
  collection_details: Yup.array().of(CollectionDetailsSchema),
});

const CollectionDetails: React.FC<{ isPreview?: boolean }> = ({ isPreview }) =>
  isPreview ? <CollectionDetailsPreview /> : <CollectionDetailsForm />;

const CollectionDetailsForm: React.FC = () => {
  const [, meta] =
    useField<CollectionDetailsFormSectionRow[]>("collection_details");

  return (
    <>
      <StyledFormHeader>Collection Details</StyledFormHeader>
      <StyledFormSectionRows>
        {meta.value.map((group, idx) => (
          <CollectionDetailsSectionRow
            key={idx}
            name={`collection_details[${idx}]`}
          />
        ))}
      </StyledFormSectionRows>
    </>
  );
};

const CollectionDetailsSectionRow: React.FC<{
  name: string;
}> = ({ name }) => {
  const [, meta] = useField<CollectionDetailsFormSectionRow>(name);

  const { available_test_names, selected_test_names } = meta.value;

  const groupDisabled = selected_test_names.length === 0;
  return (
    <>
      <span>
        {available_test_names.map((test) => {
          return (
            <CheckboxMultiField
              key={test}
              id={`${name}.selected_test_names=${test}`}
              name={`${name}.selected_test_names`}
              label={test}
              value={test}
            />
          );
        })}
      </span>
      <StyledFormSectionRows>
        <TextField
          id={`${name}.collected_at_location`}
          name={`${name}.collected_at_location`}
          label="Collection Point"
          placeholder="Kitchen, Bathtub, etc"
          disabled={groupDisabled}
        />
        <TextField
          id={`${name}.collected_by_name`}
          name={`${name}.collected_by_name`}
          label="Sample Collector"
          disabled={groupDisabled}
        />
        <DateTimeField
          id={`${name}.collected_at_datetime`}
          name={`${name}.collected_at_datetime`}
          label="Collected At"
        />
      </StyledFormSectionRows>
    </>
  );
};

const CollectionDetailsPreview: React.FC = () => {
  const [, meta] =
    useField<CollectionDetailsFormSectionRow[]>("collection_details");

  return (
    <FormPreviewSection>
      <FormPreviewHeader>Collection Details</FormPreviewHeader>
      <div>
        {meta.value
          .filter((x) => x.selected_test_names.length > 0)
          .map((x, idx, array) => (
            <>
              {" "}
              <CollectionDetailsRowPreview collection={x} />
              {idx < array.length - 1 && <hr />}
            </>
          ))}
      </div>
    </FormPreviewSection>
  );
};

const CollectionDetailsRowPreview: React.FC<{
  collection: CollectionDetailsFormSectionRow;
}> = ({ collection }) => {
  return (
    <>
      <FormPreviewText
        label="Tests"
        value={collection.selected_test_names.join(", ")}
      />
      <FormPreviewText
        label="Collection Time"
        value={format(new Date(collection.collected_at_datetime), "PPp")}
      />
      <FormPreviewText
        label="Collection Location"
        value={collection.collected_at_location}
      />
      <FormPreviewText
        label="Collected By"
        value={collection.collected_by_name}
      />
    </>
  );
};

export const groupTests = (testNames: SampleAvailableTests): string[][] => {
  const containerNames = testNames.map((x) => x.container);
  const uniqContainerNames = uniq(containerNames);

  if (uniqContainerNames.length === 1 && uniqContainerNames[0] == null) {
    return groupTestsOld(testNames.map((x) => x.name));
  }

  return Object.values(groupBy(testNames, "container")).map((x) =>
    x.map((z1) => z1.name)
  );
};

const groupTestsOld = (testNames: string[]): string[][] => {
  const specialTests = ["nitrate", "nitrite", "lead"];
  const isNorPb = (testName: string) =>
    specialTests.some(
      (specialTestName) =>
        testName.toLocaleLowerCase().indexOf(specialTestName) >= 0
    );
  const group1 = specialTests
    .map((specialTestName) =>
      testNames.find(
        (testName) => testName.toLocaleLowerCase().indexOf(specialTestName) >= 0
      )
    )
    .filter((testName) => testName !== undefined) as string[];
  const notGroup1 = testNames.filter((x) => !isNorPb(x));

  const rest = notGroup1.map((x) => [x]);

  if (group1) {
    return [group1, ...rest];
  }

  return rest;
};

export default CollectionDetails;
