import { CreateButton, SaveButton } from "../../components/Form";
import { TextField } from "../../components/FormComponents";
import { LabTest } from "../../hooks/labTests";
import {
  LabSamplingContainer,
  LabSamplingContainerFull,
  LabSamplingContainerUnsavedFull,
} from "../../hooks/samplingContainers";
import {
  LabSamplingKit,
  UnsavedLabSamplingKit,
} from "../../hooks/samplingKits";
import {
  ContainerContentDiv,
  ContainerDiv,
  ContainerTestDiv,
  ContainerTitleDiv,
  OrderHeader,
  TestTitleDiv,
} from "./style";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  Form,
  Formik,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import { initial, last, toPath } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

type SamplingKitFormType = UnsavedLabSamplingKit & {
  _kits: LabSamplingKit[];
  _containers: LabSamplingContainer[];
  _tests: LabTest[];
};

const SamplingKitForm: React.FC<{
  isEdit?: boolean;
  kit: UnsavedLabSamplingKit;
  kits: LabSamplingKit[];
  containers: LabSamplingContainer[];
  tests: LabTest[];

  onFormSave: (data: UnsavedLabSamplingKit) => Promise<void>;
}> = ({ isEdit, kit, kits, containers, tests, onFormSave }) => {
  const onSubmit = (
    data: SamplingKitFormType,
    formikHelpers: FormikHelpers<SamplingKitFormType>
  ) => {
    const { _kits, _containers, _tests, ...kit } = data;
    onFormSave(kit).finally(() => formikHelpers.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        ...kit,
        _kits: kits,
        _containers: containers,
        _tests: tests,
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <TextField id="name" name="name" label="Name" />
        <TextField id="description" name="description" label="Description" />

        <SamplingKitContainersForm name="lab_containers" />

        {isEdit ? <SaveButton /> : <CreateButton />}
      </Form>
    </Formik>
  );
};

const SamplingKitContainersForm: React.FC<{ name: string }> = ({ name }) => {
  const { meta, helpers } =
    useFieldArray<LabSamplingContainerUnsavedFull>(name);

  const addContainer = (container: LabSamplingContainerUnsavedFull) => {
    const newArray = [...meta.value, container];
    helpers.setValue(newArray);
  };

  return (
    <>
      {meta.value.map((container, idx) => (
        <SamplingKitContainerForm key={idx} name={`lab_containers.${idx}`} />
      ))}
      <AddUnusedContainer addContainer={addContainer} />
    </>
  );
};

const SamplingKitContainerForm: React.FC<{
  name: string;
}> = ({ name }) => {
  const { meta, remove, moveUp, moveDown } =
    useFieldArrayItem<LabSamplingContainerFull>(name);

  const { meta: testMeta, push: testPush } = useFieldArray<LabTest>(
    `${name}.lab_tests`
  );

  return (
    <ContainerDiv>
      <ContainerTitleDiv>
        <OrderHeader moveUp={moveUp} moveDown={moveDown} />
        <div>{meta.value.name}</div>

        <IconButton
          color="primary"
          aria-label="remove container"
          component="label"
          onClick={remove}
        >
          <RemoveIcon />
        </IconButton>
      </ContainerTitleDiv>
      <ContainerContentDiv>
        <TextField id={`${name}.name`} name={`${name}.name`} label="Name" />
        <TextField
          id={`${name}.description`}
          name={`${name}.description`}
          label="Description"
        />
        {testMeta.value.map((test, idx) => (
          <SamplingKitContainerTestForm
            key={idx}
            name={`${name}.lab_tests.${idx}`}
          />
        ))}
        <AddUnusedTest container={meta.value} addTest={testPush} />
      </ContainerContentDiv>
    </ContainerDiv>
  );
};

const SamplingKitContainerTestForm: React.FC<{
  name: string;
}> = ({ name }) => {
  const { meta, remove, moveUp, moveDown } = useFieldArrayItem<LabTest>(name);

  return (
    <ContainerTestDiv>
      <TestTitleDiv>
        <OrderHeader moveUp={moveUp} moveDown={moveDown} />
        <span>{meta.value.name}</span>

        <IconButton
          color="primary"
          aria-label="remove container"
          onClick={remove}
        >
          <RemoveIcon />
        </IconButton>
      </TestTitleDiv>
    </ContainerTestDiv>
  );
};

const AddUnusedContainer: React.FC<{
  addContainer: (container: LabSamplingContainerUnsavedFull) => void;
}> = ({ addContainer }) => {
  const { values } = useFormikContext<SamplingKitFormType>();
  const container_ids = values.lab_containers.map((container) => container.id);
  const unusedContainer = values._containers.filter(
    (t) => !container_ids.includes(t.id)
  );

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const _addContainer = (container: LabSamplingContainer) => {
    setIsOpen(false);

    const lab_tests = container.lab_tests.map((x) =>
      values._tests.find((test) => test.id === x)
    ) as LabTest[];
    const fullContainer: LabSamplingContainerFull = {
      ...container,
      lab_tests,
    };
    addContainer(fullContainer);
  };

  const _addNewContainer = () => {
    setIsOpen(false);

    addContainer({
      id: undefined,
      name: "",
      description: "",
      lab_tests: [],
    });
  };

  return (
    <ContainerDiv>
      <Button
        endIcon={<AddIcon />}
        disabled={unusedContainer.length === 0}
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        Add Container
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Add Container</DialogTitle>
        <DialogContent>
          {unusedContainer.map((container) => (
            <div key={container.id}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => _addContainer(container)}
              >
                {container.name}
              </Button>
            </div>
          ))}
          <div>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={_addNewContainer}
            >
              New Container
            </Button>
          </div>
          <DialogContentText></DialogContentText>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Subscribe</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </ContainerDiv>
  );
};

const useUnusedTests = () => {
  const { values } = useFormikContext<SamplingKitFormType>();
  const test_ids = values.lab_containers.flatMap((container) =>
    container.lab_tests.map((test) => test.id)
  );
  return values._tests.filter((t) => !test_ids.includes(t.id));
};

const AddUnusedTest: React.FC<{
  container: LabSamplingContainerFull;
  addTest: (test: LabTest) => void;
}> = ({ container, addTest }) => {
  const unusedTests = useUnusedTests();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const _addTest = (test: LabTest) => {
    setIsOpen(false);
    addTest(test);
  };

  return (
    <ContainerTestDiv>
      <Button
        endIcon={<AddIcon />}
        disabled={unusedTests.length === 0}
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        Add Test
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Add Test to {container.name}</DialogTitle>
        <DialogContent>
          {unusedTests.map((test) => (
            <div key={test.id}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => _addTest(test)}
              >
                {test.name}
              </Button>
            </div>
          ))}
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </ContainerTestDiv>
  );
};

function useFieldArray<T = any>(arrayName: string) {
  const [, meta, helpers] = useField<T[]>(arrayName);

  const push = (value: T) => helpers.setValue([...meta.value, value]);

  return { meta, helpers, push };
}

function useFieldArrayItem<T = any>(itemName: string) {
  const { index, parentArrayName } = useMemo(() => {
    const path = toPath(itemName);

    if (path.length < 2) {
      throw new Error("Item can't be top level");
    }

    const indexStr = last(path) as string;
    const parentArrayPath = initial(path);

    const index = parseInt(indexStr);
    const parentArrayName = parentArrayPath.join(".");

    return { index, parentArrayName };
  }, [itemName]);

  const [, itemMeta, itemHelpers] = useField<T>(itemName);

  const [, parentMeta, parentHelpers] = useField<T[]>(parentArrayName);

  const remove = useCallback(() => {
    const newArray = [...parentMeta.value];
    newArray.splice(index);
    parentHelpers.setValue(newArray);
  }, [index, parentMeta, parentHelpers]);

  const moveUp = useMemo(
    () =>
      index > 0
        ? () => {
            const newArray = [...parentMeta.value];
            const a = newArray[index - 1];
            const b = newArray[index];
            newArray[index] = a;
            newArray[index - 1] = b;
            parentHelpers.setValue(newArray);
          }
        : null,
    [index, parentMeta, parentHelpers]
  );

  const moveDown = useMemo(
    () =>
      index < parentMeta.value.length - 1
        ? () => {
            const newArray = [...parentMeta.value];
            const a = newArray[index + 1];
            const b = newArray[index];
            newArray[index] = a;
            newArray[index + 1] = b;
            parentHelpers.setValue(newArray);
          }
        : null,
    [index, parentMeta, parentHelpers]
  );

  return {
    meta: itemMeta,
    helpers: itemHelpers,
    remove,
    moveUp,
    moveDown,
  };
}

export default SamplingKitForm;
