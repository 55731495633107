import { StyledFormSectionRows } from "../../components/Form";
import {
  IntegerField,
  SampleIdField,
  SelectField,
  SelectOption,
} from "../../components/FormComponents";
import {
  useCreateAndGetLabels,
  useGetExistingLabels,
} from "../../hooks/labels";
import { useLabSamplingKits } from "../../hooks/samplingKits";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

const SampleKitLabelSchema = Yup.object().shape({
  count: Yup.number()
    .required("Count of Kits is required")
    .min(1, "Count of Kits must be 1 or more"),
  sample_kit: Yup.string().required("Kit is required"),
});

export const SampleKitLabels: React.FC = () => {
  const getExistingLabel = useGetExistingLabels();
  const createAndGetLabels = useCreateAndGetLabels();

  const { data: sampleKits } = useLabSamplingKits();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const toggleDialog = () => setOpen((c) => !c);

  const onPrintLabelsInner = async ({
    count,
    sample_kit,
  }: {
    count: number;
    sample_kit: string;
  }) => {
    const resp = await createAndGetLabels({ count, sample_kit });
    const pdf = await resp.blob();
    const fileName = resp.headers.get("content-disposition") ?? "label2.pdf";

    const data = window.URL.createObjectURL(pdf);
    const link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    link.click();
  };

  const onPrintLabels = (values: { count: number; sample_kit: string }) =>
    toast.promise(onPrintLabelsInner(values), {
      pending: "Creating labels",
      success: "Labels Created",
      error: "Failed to create labels",
    });

  const onReprintLabelInner = async (values: any) => {
    if (values.sampleId.trim()) {
      const resp = await getExistingLabel(values);
      const pdf = await resp.blob();
      const fileName = resp.headers.get("content-disposition") ?? "label.pdf";
      const data = window.URL.createObjectURL(pdf);
      const link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
    }
    return null;
  };

  const onReprintLabel = (values: any) =>
    toast.promise(onReprintLabelInner(values), {
      pending: "Reprinting label",
      success: "Label reprinted",
      error: "Failed to reprint label",
    });

  const kitOptions: SelectOption<string>[] = useMemo(() => {
    return (
      sampleKits?.map((x) => ({
        id: x.id,
        name: x.name,
      })) ?? []
    );
  }, [sampleKits]);

  if (!sampleKits) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          count: 1,
          sample_kit: "",
        }}
        onSubmit={onPrintLabels}
        validationSchema={SampleKitLabelSchema}
      >
        <Form autoComplete="off">
          <StyledFormSectionRows>
            <IntegerField label="Count of Kits" name={`count`} id="count" />
            <SelectField
              label="Kit"
              name="sample_kit"
              id="sample_kit"
              options={kitOptions}
            />
            <Button color="primary" variant="contained" type="submit">
              Print Labels
            </Button>
          </StyledFormSectionRows>
        </Form>
      </Formik>

      <br />
      <Button color="primary" onClick={toggleDialog}>
        Reprint Label
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Enter the Sample ID to print it's label</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              sampleId: "",
              bottle1: true,
              bottle2: true,
              bag: true,
            }}
            onSubmit={onReprintLabel}
          >
            <Form autoComplete="false">
              <StyledFormSectionRows>
                <SampleIdField
                  label="Sample Id"
                  name="sampleId"
                  id="sampleId"
                />
                <br />
                <Button color="primary" variant="contained" type="submit">
                  Print Label
                </Button>
              </StyledFormSectionRows>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};
