import { formatToMask, PHONE_MASK } from "../../utils/sample";
import { StyledFormSectionRows, StyledFormSubheader } from "../Form";
import Bugsnag from "@bugsnag/js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { format } from "date-fns";
import { FastField, FastFieldProps, Field, useField } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

export { default as SampleIdField } from "./SampleIdField";
export { default as FormContent } from "./FormContent";

export const SX = { width: 230 };
export const INPUT_LABEL_PROPS = { shrink: true };

export interface FieldProps {
  id: string;
  name: string;
  label: string;

  disabled?: boolean;
  fullWidth?: boolean;
}

type TextFieldProps = FieldProps & {
  varient?: "standard" | "filled" | "outlined";
  placeholder?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  id,
  name,
  label,
  disabled,
  fullWidth,
  varient,
  placeholder,
}) => (
  <FastField name={name} fullWidth={fullWidth}>
    {({ field, meta }: FastFieldProps) => (
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={field.value ?? ""}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={varient ?? "outlined"}
        sx={SX}
        placeholder={placeholder}
      />
    )}
  </FastField>
);

export const TextFieldSlow: React.FC<TextFieldProps> = ({
  id,
  name,
  label,
  disabled,
  fullWidth,
  varient,
  placeholder,
}) => (
  <Field name={name}>
    {({ field, meta }: FastFieldProps) => (
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={field.value ?? ""}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={varient ?? "outlined"}
        sx={SX}
        placeholder={placeholder}
      />
    )}
  </Field>
);
export const DateTimeField: React.FC<FieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth } = props;
  const [field, meta, helpers] = useField<string>(name);

  const value = useMemo(() => {
    if (
      field.value === "" ||
      field.value === undefined ||
      field.value === null
    ) {
      return "";
    }
    const newDate = new Date(field.value);
    const newValue = format(newDate, "yyyy-MM-dd'T'HH:mm");
    return newValue;
  }, [field.value]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newDate = new Date(event.target.value);
      helpers.setValue(newDate.toISOString(), true);
    },
    [helpers]
  );

  return (
    <>
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={value}
        onChange={onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        type="datetime-local"
        sx={SX}
        InputLabelProps={INPUT_LABEL_PROPS}
      />
    </>
  );
};

export const DateField: React.FC<FieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth } = props;
  const [field, meta] = useField<string>(name);

  console.assert(
    field.value === undefined ||
      field.value === null ||
      field.value === "" ||
      /\d{4}-[01]\d-[0-3]\d/.test(field.value),
    `[DateField][${name}] Value must be "" or an ISO Date String: ${field.value}`
  );

  return (
    <>
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={field.value ?? ""}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        type="date"
        sx={SX}
        InputLabelProps={INPUT_LABEL_PROPS}
      />
    </>
  );
};

export const IntegerField: React.FC<FieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth } = props;
  const [field, meta] = useField(name);

  return (
    <MuiTextField
      id={id}
      name={field.name}
      label={label}
      value={field.value ?? ""}
      onChange={field.onChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      type="number"
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
    />
  );
};

export const FloatField: React.FC<FieldProps & { decimalPlaces: number }> = (
  props
) => {
  const { id, name, label, disabled, fullWidth, decimalPlaces } = props;
  const [field, meta, helpers] = useField<string>({ name });

  return (
    <MuiTextField
      {...field}
      id={id}
      onBlur={(x) => {
        const { value } = x.target;
        helpers.setValue(parseFloat(value).toFixed(decimalPlaces));
        field.onBlur(x);
      }}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      type="number"
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
    />
  );
};

export const FloatOrTextField: React.FC<
  FieldProps & { decimalPlaces: number }
> = (props) => {
  const { id, name, label, disabled, fullWidth, decimalPlaces } = props;
  const [field, meta, helpers] = useField<string>({ name });

  return (
    <MuiTextField
      {...field}
      id={id}
      onBlur={(x) => {
        const { value } = x.target;
        const valueNum = parseFloat(value);
        if (!isNaN(valueNum)) {
          x.target.value = valueNum.toFixed(decimalPlaces);
          helpers.setValue(x.target.value);
        }
        field.onBlur(x);
      }}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
    />
  );
};

export const NumberField: React.FC<FieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth } = props;
  const [field, meta] = useField<string>({ name });

  return (
    <MuiTextField
      {...field}
      id={id}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      type="number"
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
    />
  );
};

export const PhoneField: React.FC<FieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth } = props;
  const [field, meta, helpers] = useField(name);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    helpers.setValue(formatToMask(PHONE_MASK)(value));
  };

  return (
    <MuiTextField
      id={id}
      name={field.name}
      label={label}
      value={field.value ?? ""}
      onChange={onChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
    />
  );
};

type TextAreaFieldProps = FieldProps & {
  minRows?: number;
  maxRows?: number;
  rows?: number;
};

export const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const { id, name, label, disabled, fullWidth, minRows, maxRows, rows } =
    props;
  const [field, meta] = useField(name);

  console.assert(
    field.value !== undefined,
    `[TextAreaField][${name}] Value must not be set`
  );

  return (
    <MuiTextField
      id={id}
      name={field.name}
      label={label}
      value={field.value}
      onChange={field.onChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      disabled={disabled}
      fullWidth={fullWidth}
      multiline
      minRows={minRows}
      maxRows={maxRows}
      rows={rows}
    />
  );
};

export interface SelectOption<T extends string> {
  id: T;
  name: string;
}
type SelectFieldProps<T extends string> = FieldProps & {
  options: SelectOption<T>[];
};

export const SelectField = <T extends string>({
  id,
  name,
  label,
  disabled,
  fullWidth,
  options,
}: React.PropsWithChildren<SelectFieldProps<T>>) => (
  <FastField name={name}>
    {({ field, meta }: FastFieldProps) => (
      <MuiTextField
        id={id}
        name={field.name}
        label={label}
        value={field.value ?? ""}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        fullWidth={fullWidth}
        sx={SX}
        select
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
      </MuiTextField>
    )}
  </FastField>
);

export const SelectField2 = <T extends string>(
  props: React.PropsWithChildren<SelectFieldProps<T>>
) => {
  const { id, name, label, disabled, fullWidth, options } = props;
  const [field, meta] = useField({ name, type: "checkbox", multiple: false });

  useEffect(() => {
    // TODO - Remove once figured out
    if (field.value && options.findIndex((o) => o.id === field.value) < 0) {
      Bugsnag.notify(new Error("Selected values doesn't have matching option"));
    }
  }, [field.value, options]);

  return (
    <MuiTextField
      id={id}
      name={field.name}
      label={label}
      value={field.value ?? ""}
      onChange={field.onChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={SX}
      select
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {options.map((o) => (
        <MenuItem key={o.id} value={o.id}>
          {o.name}
        </MenuItem>
      ))}
    </MuiTextField>
  );
};

const YesNoOptions: SelectOption<string>[] = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
];
export const YesNoField: React.FC<FieldProps> = (props) => (
  <SelectField {...props} options={YesNoOptions} />
);

export const CheckboxField: React.FC<{
  id: string;
  label: string;
  name: string;
  fullWidth?: boolean;
  value?: string;
}> = ({ id, label, name, fullWidth }) => (
  <FastField name={name} type="checkbox">
    {({ field }: FastFieldProps) => (
      <FormControl fullWidth={fullWidth}>
        <FormControlLabel
          control={<Checkbox color="primary" id={id} {...field} />}
          label={label}
        />
      </FormControl>
    )}
  </FastField>
);

export const CheckboxMultiField: React.FC<{
  id: string;
  label: string;
  name: string;
  value: string;
  fullWidth?: boolean;
}> = (props) => {
  const { id, name, label, fullWidth, value } = props;

  const [field] = useField({
    name,
    type: "checkbox",
    value,
    multiple: true,
  });

  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={field.value ?? false}
            id={id}
            {...field}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export const SelectMultipleField = <T extends string>(
  props: React.PropsWithChildren<SelectFieldProps<T>>
) => {
  const { id, name, label, options } = props;

  const [input, meta] = useField<string[]>(name);

  const renderValue = (selectedRaw: any) => {
    const selected = selectedRaw as string[];

    if (selected.length === options.length) {
      return <em>All</em>;
    }

    return selected.join(", ");
  };

  return (
    <FormControl variant="outlined" sx={SX}>
      <InputLabel id={`${id}-label`} filled>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        multiple
      >
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            <Checkbox color="primary" checked={meta.value.includes(o.id)} />
            <ListItemText primary={o.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectMultipleGridField = <T extends string>(
  props: React.PropsWithChildren<SelectFieldProps<T>>
) => {
  const { id, name, label, options } = props;

  const [input, , helpers] = useField<string[] | undefined>(name);

  const allSelected = input.value?.length === options.length;
  const someSelected = (input.value?.length ?? 0) > 0;

  const handleChange1 = () => {
    if (allSelected) {
      helpers.setValue([]);
    } else {
      helpers.setValue(options.map((x) => x.id));
    }
  };

  return (
    <>
      <StyledFormSubheader>
        {label}
        &emsp;
        <FormControlLabel
          control={
            <Checkbox
              checked={allSelected}
              indeterminate={someSelected && !allSelected}
              onChange={handleChange1}
            />
          }
          label="Select All"
        />
      </StyledFormSubheader>
      <StyledFormSectionRows>
        <FormControl variant="outlined">
          <GridContainer>
            {options.map((o) => (
              <CheckboxOption key={o.id} id={id} name={name} option={o} />
            ))}
          </GridContainer>
        </FormControl>
      </StyledFormSectionRows>
    </>
  );
};

const GridContainer = styled(FormGroup)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
`;

interface CheckboxOptionProps<T extends string> {
  id: string;
  name: string;
  option: SelectOption<T>;
}
const CheckboxOption = <T extends string>(
  props: React.PropsWithChildren<CheckboxOptionProps<T>>
) => {
  const { id, name, option } = props;

  const [input] = useField<string[]>({
    name,
    type: "checkbox",
    value: option.id,
  });

  return (
    <FormControlLabel
      control={<Checkbox id={`${id}-${option.id}`} {...input} />}
      label={option.name}
    />
  );
};
