import { useUser } from "../hooks/user";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Tooltip } from "@mui/material";
import React from "react";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const apiUser = useUser();

  return (
    <Tooltip title="Logout" placement="bottom">
      <Button
        color="secondary"
        variant="outlined"
        // eslint-disable-next-line no-restricted-globals
        onClick={() =>
          isAuthenticated
            ? logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            : loginWithRedirect()
        }
      >
        {isAuthenticated ? apiUser?.name : "Log In"}
      </Button>
    </Tooltip>
  );
};

export default LoginButton;
