import { useField } from "formik";
import styled from "styled-components";

export const FormPreviewSection = styled.section`
  div {
    display: grid;
    grid-template-columns: 20rem max-content;
    gap: 1rem;

    hr {
      grid-column: 1 / span 2;
    }

    .label {
      font-weight: lighter;
      text-align: right;
    }

    .value {
    }
  }
`;
export const FormPreviewHeader = styled.h1`
  border-bottom: 1px solid rgba(100, 100, 100, 0.4);
  padding-bottom: 8px;
  margin-bottom: 16px;
`;
export const FormPreviewTextField: React.FC<{
  id: string;
  name: string;
  label: string;
}> = ({ id, name, label }) => {
  const [, { value }] = useField(name);
  return (
    <>
      <span className="label">{label}:</span>
      <span className="value">{value}</span>
    </>
  );
};

export const FormPreviewCheckboxField: React.FC<{
  id: string;
  name: string;
  label: string;
}> = ({ id, name, label }) => {
  const [, { value }] = useField(name);
  return (
    <>
      <span className="label">{label}:</span>
      <span className="value">{value ? "Yes" : "No"}</span>
    </>
  );
};

export const FormPreviewText: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => (
  <>
    <span className="label">{label}:</span>
    <span className="value">{value}</span>
  </>
);
