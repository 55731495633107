import { useField } from "formik";
import React from "react";
import { StyledFormHeader, StyledFormSectionRows } from "src/components/Form";
import { TextField } from "src/components/FormComponents";
import {
  FormPreviewHeader,
  FormPreviewSection,
  FormPreviewTextField,
} from "src/components/FormPreview";
import * as Yup from "yup";

export interface SiteDetailsFormSection {
  site_owner: string;
  site_address_street: string;
  site_address_city: string;
  site_address_state: string;
  site_address_zip: string;
  site_address_county: string;
  site_address_township: string;
}

export const SiteDetailsSectionSchema = Yup.object({
  site_owner: Yup.string().required("Required"),
  site_address_street: Yup.string().when("custom_fields.well_type", {
    is: (v: string) => v !== "public",
    then: (schema) => schema.required("Required"),
  }),
  site_address_city: Yup.string().required("Required"),
  site_address_state: Yup.string().required("Required"),
  site_address_zip: Yup.string().required("Required"),
});

const SiteDetails: React.FC<{ isPreview?: boolean }> = ({
  isPreview = false,
}) => (isPreview ? <SiteDetailsPreview /> : <SiteDetailsForm />);

const SiteDetailsPreview: React.FC = () => (
  <>
    <FormPreviewSection>
      <FormPreviewHeader>
        <SiteName />
      </FormPreviewHeader>
      <div>
        <FormPreviewTextField id="site_owner" name="site_owner" label="Owner" />
        <FormPreviewTextField
          id="site_address_street"
          name="site_address_street"
          label="Property Address"
        />
        <FormPreviewTextField
          id="site_address_city"
          name="site_address_city"
          label="City"
        />
        <FormPreviewTextField
          id="site_address_state"
          name="site_address_state"
          label="State"
        />
        <FormPreviewTextField
          id="site_address_zip"
          name="site_address_zip"
          label="Zip"
        />
        <FormPreviewTextField
          id="site_address_county"
          name="site_address_county"
          label="County"
        />
        <FormPreviewTextField
          id="site_address_township"
          name="site_address_township"
          label="Township"
        />
      </div>
    </FormPreviewSection>
  </>
);

const SiteDetailsForm: React.FC = () => (
  <>
    <StyledFormHeader>
      <SiteName />
    </StyledFormHeader>
    <StyledFormSectionRows>
      <TextField id="site_owner" name="site_owner" label="Owner" />
      <TextField
        id="site_address_street"
        name="site_address_street"
        label="Property Address"
      />
      <TextField id="site_address_city" name="site_address_city" label="City" />
      <TextField
        id="site_address_state"
        name="site_address_state"
        label="State"
      />
      <TextField id="site_address_zip" name="site_address_zip" label="Zip" />
      <TextField
        id="site_address_county"
        name="site_address_county"
        label="County"
      />
      <TextField
        id="site_address_township"
        name="site_address_township"
        label="Township"
      />
    </StyledFormSectionRows>
  </>
);

const SiteName: React.FC = () => {
  const [, meta] = useField("custom_fields.well_type");

  return (
    <>
      {meta.value === "public"
        ? "Public Water Supply System Name"
        : "Site Information"}
    </>
  );
};

export default SiteDetails;
